import { Component , OnInit, ViewChild  } from '@angular/core';
import { DatePipe,formatDate} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {CodeListValues, SupplierContact,SupplierProfile,SponsorContact, SuppliersMasterServiceAgreement, PaymentTermsDropdownBinding, KpiChildComponents, AuditChildComponents, MeetingChildComponents} from 'src/app/MasterData/Services/supplier-profile';
import { FormBuilder, FormGroup,  Validators,AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { ConfirmationService, MessageService,  MenuItem } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { ActiveInActiveStatus,Dateformat, breadcrumnsCodes, AuditIdentifier, AuditLogEntities, AuditOperations, AuditLogModule } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { Observable, forkJoin, tap} from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';
import { AuditLogModel } from 'src/app/shared/ModelComparison/audit.log.response.model';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { UserGroupModel } from 'src/app/Admin/Authorization/UserRoleMapping/Models/user';
import { } from 'src/app/Admin/Authorization/UserRoleMapping/Services/user.service';
import { PrivilegeService } from 'src/app/Reusable/privilege.service';
import { Contact, EngagementMasterServiceAgreement, EngagementPostModel, StakeholderContacts } from '../../Models/Engagement';
import { EngagementService } from '../../Services/engagement.service';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { FileUploadService } from '../../Services/fileupload.service';
import { EngCreateAuditComponent } from 'src/app/Governance/audits/Engagements/Components/eng-create-audit/eng-create-audit.component';
import { EngEditAuditComponent } from 'src/app/Governance/audits/Engagements/Components/eng-edit-audit/eng-edit-audit.component';
import { EngCreateKpiComponent } from 'src/app/Governance/kpis/Engagement/Components/eng-create-kpi/eng-create-kpi.component';
import { EngEditKpiComponent } from 'src/app/Governance/kpis/Engagement/Components/eng-edit-kpi/eng-edit-kpi.component';
import { EngCreateMeetingComponent } from 'src/app/Governance/meetings/Engagements/Components/eng-create-meeting/eng-create-meeting.component';
import { EngEditMeetingRecYesComponent } from 'src/app/Governance/meetings/Engagements/Components/eng-edit-meeting-rec-yes/eng-edit-meeting-rec-yes.component';
import { EngEditMeetingComponent } from 'src/app/Governance/meetings/Engagements/Components/eng-edit-meeting/eng-edit-meeting.component';
import { AssociateAddEditComponent } from 'src/app/Team/teams/associate-add-edit/associate-add-edit.component';
import { TabView } from 'primeng/tabview';
import { TeamChildComponents } from 'src/app/Team/teams/Models/Associate';
import { CurrencyService } from 'src/app/Reusable/currency.service';
import { ContractService } from 'src/app/Contracts/Services/contract.service';
import { DashboardService } from 'src/app/Dashboard/Services/dashboard.service';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { environment } from 'src/environments/environment';
import { AuthCommonService } from 'src/app/shared/Authentication/auth.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';
import { ConversionRate } from 'src/app/Reusable/conversion-rate';
import { Privileges } from 'src/app/shared/constants/privileges.constants';
import { FinanceService } from 'src/app/Finance/Services/invoice.service';

@Component({
  selector: 'app-add-edit-engagement',
  templateUrl: './add-edit-engagement.component.html',
  styleUrls: ['./add-edit-engagement.component.css']
})
export class AddEditEngagementComponent  implements OnInit,IDeactivateGuard   {
  isSaveBtnDisable = false;
  @ViewChild('myTabView', { static: false }) tabView!: TabView;
  activeTabIndex = 0;
  @ViewChild('mySubTabView', { static: false }) subTabView!: TabView;
  @ViewChild(EngCreateAuditComponent) childAuditCreateComponent!: EngCreateAuditComponent;
  @ViewChild(EngEditAuditComponent) childAuditEditComponent!: EngEditAuditComponent;
  @ViewChild(EngCreateKpiComponent) childKpiCreateComponent!: EngCreateKpiComponent;
  @ViewChild(EngEditKpiComponent) childKpiEditComponent!: EngEditKpiComponent;
  @ViewChild(EngCreateMeetingComponent) childMeetingCreateComponent!: EngCreateMeetingComponent;
  @ViewChild(EngEditMeetingRecYesComponent) childMeetingRecYesEditComponent!: EngEditMeetingRecYesComponent;
  @ViewChild(EngEditMeetingComponent) childMeetingEditComponent!: EngEditMeetingComponent;
  @ViewChild(AssociateAddEditComponent) childTeamCreateEditComponent!: AssociateAddEditComponent;
  
  engagementId: any;
  engagementName: any;
  engagementStartDate:any
  heading:any;
  currencyCodeList : any[] = [];
  visible: boolean = false;
  position: string = 'center';
  showDialog() {
    this.visible = true;
}
  _engagement=new EngagementPostModel()
  _contact= new Contact();
  displayDialog: boolean = false;
  locationsDataSource: CodeListValues[] | undefined;
  notificationOptionsDataSource: CodeListValues[] | undefined;
  servicesDataSource:any[]=[];
  supplierServicesDataSource:any[]=[];
  sponserDataSource:any[]=[];
  engagementForm: FormGroup;
  contactForm: FormGroup;
  supplierMSAForm: FormGroup;
  isFormDirty = false;
  submitted = false;
  contactSubmitted=false;
  selectedItem: any;
  oldSelectedItem: any;
  supplierdataSource : any[] = [];
  uniqueMessage=false;
  uniqueCombinationMessage=false;
  _dateformart :any;
  isLoading=false;
  executeCanExist: boolean = true;
  breadcrumbs: MenuItem[]=[];
  isEditPage=false;
  //MSA Logic

  supplierContactsDataSource : SupplierContact[]=[];
  sponsorContactsDataSource : SponsorContact[]=[];
  msaSupplierContactsDataSource : SupplierContact[]=[];
  msaSponsorContactsDataSource : SponsorContact[]=[];


  formData =  new  FormData();
  displayMSADialog: boolean = false;
  msaStatusDataSource : any[]=[]; 
  paymentTermsDataSource : any[]=[];
  functionsDataSource : any[]=[];
  
  selectedMSAItem: any;
  oldSelectedMSAItem: any;
  msaSaved = false;
  enableAddMSA=false;
  _supplierMSADetails: EngagementMasterServiceAgreement[]=[];
  _enumBreadcrumb :any;
  msaPopHeader='Add MSA';
  minEndDate:any;
  maxStartDate:any;
  isEditMSA= false;
  oldEngagement:any;
  statusData: any=[];
  defaultstatus: any=[];
  contactHeader='Add Contact';
  oldEngagementData:any;
  oldEngagementSupplierContact:any;
  oldEngagementSponsorContact:any;
  oldEngagementMSA:any;
  blankEngagementData:any;
  blankEngagementSupplierContact:any;
  blankEngagementSponsorContact:any;
  blankEngagementMSA:any;
  auditIdentifierDetails:any;

  usergroup=new UserGroupModel();
  selectedSponsorContactId:any
  stakeholderSponsorContacts: any[] = [];
  stakeholderSupplierContacts:any[]=[];
  stakeholderContact:StakeholderContacts={};
    engagementContractsGroups: any;

    _currentAuditChildComponent: number = 0;
    _currentKpiChildComponent: number = 0;
    _currentMeetingChildComponent: number = 0;
    _currentTeamChildComponent: number = 0;
    auditId: any;
    kpiId: any;
    meetingId: any;
    StatusMappingId: any;
    supplierStatus: any;
    currentChildComponent: any;
    associateId: any;
    actionType: any;
    activeSubTabIndex = 0;
    FromType = 2;
    subTabindex: number = 0;
    previousSubTabIndex = 0;
    isformObjectChange = false;
    _message = "";
    _detail = "";
    _oldObject: string = "";
    _newObject: string = "";
    _commonEditDetails = "Changes not saved";
    _commonEditMessage = "Changes will not be saved, Do you want to Continue?";
    Previoustabindex: number = 0
    editTeamPrivilege: boolean = false;
    showSummaryView: boolean = false;
    editPrivilege: boolean = false;
    viewFinancePrivilege: boolean = false;
    viewTeamPrivilege: boolean = false;
    _kpiChildComponents = new KpiChildComponents();
    _auditChildComponents = new AuditChildComponents();
    _meetingChildComponents = new MeetingChildComponents();
    _teamChildComponents = new TeamChildComponents();
    isContractTab=false;
    SupplierId: any;
    SponsorId:any;
    allMsaSponsorContactsDataSource:any;
    allMsaSupplierContactsDataSource:any;
    uniqueEngagementMessage=false;
    allEngagementdetails: any[] = [];
    filteredSponsorContacts: any[] = [];
    filteredSupplierContacts: any[] = [];
  // dialogFormGroup: FormGroup;
  constructor( 
    private privilegeService: PrivilegeService,
   // private _userService: UserService,
    private activeRouter: ActivatedRoute,
    private router: Router,
    private supplierService: MasterSupplierService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private masterDataService: SponserService,
    private loaderService: LoaderService,
    private breadcrumbService: BreadcrumbService,
    private fileUploadService: FileUploadService,
    private datepipe: DatePipe,
    private auditLogService:AuditLogService,
    private engagementService: EngagementService,
    private currencyService:CurrencyService,
    private _contractService:ContractService,
    private _dashboaredService:DashboardService,
    private authService: AuthCommonService,
    private tabAndStateService: TabAndStateService,
    private _financeInvoiceService:FinanceService
     )


    {
     this.engagementForm = this.fb.group({
    _sponsor:['', [Validators.required]],
    _supplier:['', [Validators.required]],
    _enagementName: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(3),Validators.pattern(/^[\S\s]*$/),this.notOnlySpacesValidator]],
    _engagementID:['',[Validators.required,Validators.maxLength(10),Validators.minLength(3),Validators.pattern(/^(?=.*[A-Za-z0-9-])[A-Za-z0-9-]{3,10}$/)]],
    _engagementStartDate:['',[Validators.required]],
    _services: ['', [Validators.required]],
    _defaultCurrency:['', [Validators.required]],
    _notes:['',[Validators.maxLength(300)]],
    _statusMappingId: ['', [Validators.required]],
  });



  this.contactForm = this.fb.group({
    _contactName: ['', [Validators.required, Validators.maxLength(30),Validators.pattern(/^[a-zA-Z\s]{1,30}$/),this.notOnlySpacesValidator]],
    _email:['',[Validators.required,Validators.maxLength(100)]],
    _countryCode:[''],
    _phoneNumber:['',[Validators.minLength(7),Validators.maxLength(15),Validators.pattern(/^[0-9]*$/)]],
    _designation:['', [Validators.maxLength(100),Validators.pattern(/^[\S\s]*$/),this.notOnlySpacesValidator]],
  });

  // MSA Form
this.supplierMSAForm = this.fb.group({	
  _msaTitle: ['', [Validators.required,Validators.maxLength(50),Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]{1,50}$/)]],	
  _msaStartDate:['',[Validators.required]],	
  _msaEndDate:['',[Validators.required]],	
  _paymentTerms: ['', [Validators.required]],	
  _msaStatus: ['', [Validators.required]],	
  _msaDescription: ['',[Validators.maxLength(300)]],	
  _msafileSource: ['', Validators.required],
  _msaSponsorName: ['', [Validators.required]],	
  _msaSupplierName: ['', [Validators.required,this.validateStatus]],	
  _msaFileName: '',
  
}); 
 }


 validateStatus(control: FormControl) {
  const status = control.value;
  if (status === undefined || status === 0) {
      return { required: true }; // Validation failed
  }
  return null; // Validation passed
}


 auditIdentifier:any;
 auditEntities:any;
 auditOperations:any;
 auditModule:any;
 currentTab:any;
 _supplierIds:any
 _sponsorIds:any
    ngOnInit() {
     this.activeRouter.params.subscribe(params => {
        this.engagementId = params['id']; //log the value of id
      });
      if(this.engagementId!=undefined)
      {
       this.heading="Edit Engagement";
       this.isEditPage=true;
     
      }
      else
      {
      this.engagementId=0;
      this.heading="Create Engagement"
      this.isEditPage=false;
      }
      let userData=this.privilegeService.getUserData();
      this.usergroup.userId=userData.id;
      this.usergroup.email=userData.emailId;
      this.usergroup.groupTypeCode=userData.groupTypeCode;
      this._supplierIds=userData.suppliers;
      this._sponsorIds=userData.sponsers;
      this._enumBreadcrumb=breadcrumnsCodes;
      this.auditEntities=AuditLogEntities;
      this.auditOperations=AuditOperations;
      this.auditModule=AuditLogModule;
      if(!this.isEditPage){
      this.generateBreadcrumb();
      }
      else{
        this.generateBreadcrumbforEdit(this._enumBreadcrumb.EVENG,"","Profile");
      }
      this.GetEngagementStatus();
      this._dateformart=Dateformat;  
      this.auditIdentifier=AuditIdentifier;  
      this.GetAllSuppliers(this.usergroup.groupTypeCode,this._supplierIds);
      this.GetAllSponsers(this.usergroup.groupTypeCode,this._sponsorIds) ;
      this.GetAllCountries(0);
      this.GetAllNotificationOptions(20);
      this.GetAllServices(1);
      this.GetCurrencyData();
     
     this.formOldEnagementdData();

     this.blankEngagementData=JSON.stringify(this._engagement);
     this.blankEngagementSupplierContact=JSON.stringify(this._contact);
     this.blankEngagementSponsorContact=JSON.stringify(this._contact);
     this.blankEngagementMSA=JSON.stringify(this._supplierMSADetails);
     this.auditIdentifierDetails= AuditIdentifier;

    if(this.isEditPage){
     
      setTimeout(() => {
     this.BindEngagementModel();
    }, 1000);
    }
    this.currentTab = this.tabAndStateService.getPreviousState();
  
    this.BindPrivileges();
    this.GetAllEngagements();
  }
  TeamsDashboardPrivilage:boolean=false;
  viewFinanceDashboard=false;
  BindPrivileges(){
 const hasPermission = (privilege: Privileges) => this.privilegeService.hasPermission(privilege);
 this.TeamsDashboardPrivilage= hasPermission(Privileges.ViewFunctionsTeamsDashboard);
 this.viewFinanceDashboard=hasPermission(Privileges.ViewFunctionsFinanceDashboard);
 this.viewFinancePrivilege= hasPermission(Privileges.ViewEngagementFinance);
 
 }
       
    formOldEnagementdData(){
      setTimeout(() => {
        this.oldEngagement=JSON.stringify(this._engagement);
      }, 2000);
    }
    notOnlySpacesValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value.trim() === '') {
       return { 'onlySpaces': true };
     }
     return null;
   }
   
GetAllSponsers(groupType:any,grouIds:any) {
  if(groupType=="SPO")
  {
  this.masterDataService.GetAllSponsersByGroup(grouIds)
  .subscribe(res=>{
    this.sponserDataSource=res.data;
    this.sponserDataSource = this.sponserDataSource?.filter(t=>t.isDeleted==false);
    this.sponserDataSource = this.sponserDataSource.map(obj => ({ name: `${obj.name} (${obj.sponserId})`,id:obj.id }));
  });
}
else
{
  this.masterDataService.GetAllSponsers()
  .subscribe(res=>{
    this.sponserDataSource=res.data;
    this.sponserDataSource = this.sponserDataSource?.filter(t=>!t.isDeleted);
    this.sponserDataSource = this.sponserDataSource.map(obj => ({ name: `${obj.name} (${obj.sponserId})`,id:obj.id }));
  });
}
};


GetCurrencyData(){
  this.masterDataService.getCodeListData(4,'').subscribe(
    data=>{
      this.currencyCodeList=data.data;
      this.currenciesData = data.data;
      setTimeout(() => {
        this.setDefaultCurrency();
      }, 1000);
      this.GetConversionRates();
    }
  );
}


userInput: string = '';
onSupplierChange() {

  if(!this.isEditPage){
  this._engagement.services=[];
  }
 
  


  const selectedSupplier = this.supplierdataSource.find(supplier => supplier.id === this._engagement.supplierId);

  if(selectedSupplier != undefined)
    {
      const engagementSupplier = this._AllSuppliersData.find((supplier: { id: number | undefined; }) => supplier.id === this._engagement.supplierId);
      this.supplierName=engagementSupplier.name;
  this.userInput = selectedSupplier.supplierServices;
  const inputArray =selectedSupplier.supplierServices.split(',').map((item: string) => item.trim());
  this.supplierServicesDataSource = this.servicesDataSource.filter(service =>
    inputArray.includes(service.name)
  );
}


  this.GetAllsupplierContacsById(this._engagement.supplierId);


}

onSponsorChange() {
  this.GetAllSponsorContactsById(this._engagement.sponsorId);
}

selectedPaymentTerms: string='';
onPaymentTermsChange(){
  const selectedPayments = this.paymentTermsDataSource.find(payment => payment.id === this.supplierMSAForm.value._paymentTerms);
    this.selectedPaymentTerms = selectedPayments ? selectedPayments.label : '';
}

//get SendNotification options data
GetAllNotificationOptions(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.notificationOptionsDataSource=res.data;
    }
  );
};
//get country data
GetAllCountries(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.locationsDataSource=res.data;
    }
  );
};

//get Services data
GetAllServices(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.servicesDataSource=res.data;

      if(this.isEditPage){
      this.supplierServicesDataSource=this.servicesDataSource;
      }
    }
  );
};

//get all suppliers data..using
_AllSuppliersData:any;
supplierName:any="";
GetAllSuppliers(groupType:any,groupIds:any) {
  if(groupType=="SUP")
  {
    this.supplierService.GetSuppliersByGroups(groupType,groupIds).subscribe(
    res=>{
    this.supplierdataSource=res.data;
    this._AllSuppliersData=res.data;
    this.supplierdataSource = this.supplierdataSource?.filter(t=>!t.isDeleted);
    this.supplierdataSource = this.supplierdataSource.map(obj => ({ name: `${obj.name} (${obj.supplierId})`,id:obj.id,supplierServices:obj.supplierServices }));
    } 
 );
}
else{
  this.supplierService.GetSuppliers().subscribe(
    res=>{
    this.supplierdataSource=res.data;
    this._AllSuppliersData=res.data;
    this.supplierdataSource = this.supplierdataSource?.filter(t=>!t.isDeleted);
    this.supplierdataSource = this.supplierdataSource.map(obj => ({ name: `${obj.name} (${obj.supplierId})`,id:obj.id,supplierServices:obj.supplierServices }));
    } 
 );
}
};


  OnSaveEngagement() {
    this.submitted = true;
    this.isSaveBtnDisable = true;
    setTimeout(() => {
      this.isSaveBtnDisable = false;
    }, 3000);
    if (this.engagementForm.valid && !this.uniqueMessage && !this.uniqueCombinationMessage && !this.uniqueEngagementMessage) {
      this.loaderService.showLoader();
      this.executeCanExist = false;

      this._engagement.createdBy= this.privilegeService.getLoginUserId();
     this._engagement.sponsorContacts = this.stakeholderSponsorcontactData.filter(record => record.id !== null && record.id!==0);
     this._engagement.supplierContacts=this.stakeholderSuppliercontactData.filter(record => record.id !== null && record.id!==0);
      if (this._supplierMSADetails?.length >0) {
            this._supplierMSADetails.forEach(item => {
                if(item.isPrevFileDelete || item.id==0){
                  if(item.uploadedMSAFile!=undefined){
                  this.formData.append("file",item.uploadedMSAFile,item.filePath);
                  }
                }
              item.startDate = this.datepipe.transform(item.startDate, "MM/dd/yyyy");
              item.endDate = this.datepipe.transform(item.endDate, "MM/dd/yyyy");
            }); 
           this.fileUploadService.UploadMultipleFile(this.formData).subscribe((isUploaded: any) => {
            if (isUploaded)
            {  
              this._engagement.engagementStartDate = this.datepipe.transform(this._engagement.engagementStartDate, "MM/dd/yyyy");
              this._engagement.engagementMasterServiceAgreements=this._supplierMSADetails;
             
             if(!this.isEditPage){
              this.engagementService.AddEngagement(this._engagement).subscribe(res => {
                this.messageService.add({ key: 'tc', severity: 'success', summary: 'Engagement Information has been saved', detail: '' });
                if(res.status)
                {
                  this.privilegeService.setGroup(res.data,3);
                  this.loaderService.hideLoader();
                  this.router.navigate(['/ViewEngagement',res.data])
                }
              });
            }
            else//Update
            {
              this._engagement.updatedBy= this.privilegeService.getLoginUserId();
              this.engagementService.UpdateEngagement(this._engagement).subscribe(res => {
              
                if(res.status)
                {

                  if(this.makeContractInActive)
                  {
                    this.UpdateContractsStatus();
                  }
                  
                  // else{
                    this.messageService.add({ key: 'tc', severity: 'success', summary: 'Engagement Information has been updated', detail: '' });
                   
                  this.loaderService.hideLoader();
                  this.router.navigate(['/ViewEngagement',res.data])
                  }
                //}
              });
            }

              this.compareModels();
              
          }
          else
          {
              this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Failed to save added MSA attachments, please try after sometime', detail: '' })
              this.loaderService.hideLoader();
          }
        });
      } 
      else{
        this.loaderService.hideLoader();
       
        this.messageService.add({  key: 'tc',severity: 'warn', summary: 'At least 1 MSA should be added to save an Engagement', detail: '' }); 
      }
    }
    else{
      let alertMessage="Please enter required information";
      if(this.uniqueCombinationMessage==true)
      {
        alertMessage ="An Engagement already exists with selected Sponsor and Supplier. A duplicate cannot be created. Please change the Sponsor/Supplier selection.";
      }
      this.messageService.add({  key: 'tc',severity: 'warn', summary: alertMessage });  
    }
      }

    
      UpdateContractsStatus(){
        this.masterDataService.getCodeListData(3, 'Contract').subscribe(
          data => {
              this.statusData = data.data;
              let inActiveContractStatusId=this.statusData.filter((aa:any)=>aa.name=='InActive')[0].id;
              this._contractService.UpdateContractStatus(this.engagementId,inActiveContractStatusId).subscribe(
                res=>{


                });
              

          }
      );
      }
    isSavePopupBtnDisable = false;
    iscontactExist=false;
      saveContactData() {
        this.isSavePopupBtnDisable = true;
        setTimeout(() => {
          this.isSavePopupBtnDisable = false;
        }, 3000);
        this.contactSubmitted = true;
        if (this.contactForm.valid && !this.iscontactExist) {
          if(this.currentHeader==1)
          {
            this._contact.createdBy=this.privilegeService.getLoginUserId();
            this._contact.sponserId=this._engagement.sponsorId;
            this._contact.name=this._contact.contactName;
            this.masterDataService.AddSponsorContact(this._contact).subscribe(
            res=>{
             this.displayDialog = false;
             this.GetAllSponsorContactsById(this._engagement.sponsorId);
             this.messageService.add({
               key: 'tc',
               severity: 'success',
               summary: 'Sponsor Contact Information has been saved',
               detail: '',
           });
            });
          }
          else{
            this._contact.createdBy=this.privilegeService.getLoginUserId();
            this._contact.supplierProfileId=this._engagement.supplierId;
            this.supplierService.AddSupplierContact(this._contact).subscribe(
              res=>{
               this.displayDialog = false;
               this.GetAllsupplierContacsById(this._engagement.supplierId);
               this.messageService.add({
                 key: 'tc',
                 severity: 'success',
                 summary: 'Supplier Contact Information has been saved',
                 detail: '',
             });
              });
          }
      }
    }

    engagement_ID:any
    checkExist() {
    
      if(this._engagement.supplierId!=undefined && this._engagement.sponsorId)
      {
        this.engagement_ID=this._engagement.engagementId?.replace(/-/g, '~');
      this.engagementService.checkExist(this.engagement_ID,this.engagementId,this._engagement.sponsorId,this._engagement.supplierId).subscribe(
        res=>{
          if(res.data == 1){
            this.uniqueMessage=true;
          }
          else{
            this.uniqueMessage=false;
          }
          if(res.data == 2){
            this.uniqueCombinationMessage=true;
            this.messageService.add({
              key: 'tc',severity: 'warn', summary: 'An Engagement already exists with selected Sponsor and Supplier. A duplicate cannot be created. Please change the Sponsor/Supplier selection.',
              detail: '',
          });

          }
          else{
            this.uniqueCombinationMessage=false;
          }
        }
      );
      }
    }

      cancelDialog() {
        this.displayDialog = false;
        this.selectedItem=null;
        this.messageService.add({
          key: 'tc',
          severity: 'info',
          summary: 'Contact Information has not been saved',
          detail: '',
      });
      }
     

    onCancelEngagementCreate()
    {
      if(this.isEditPage)
        {
          this.router.navigate(['/ViewEngagement',this.engagementId])
        }
        else
        {
          this.router.navigate(['/home'])
        }
    }

    
// MSA related changes
GetMSAStatusMasterData(enumId:number,type:string='') {
  this.msaStatusDataSource = [];
  this.masterDataService.getCodeListData(enumId,type).subscribe(
    res=>{
     this.msaStatusDataSource   = res.data;
     if(!this.isEditMSA)
     this.setMSA_StatusDefaultValue();
    }
  );
};

GetPaymentTermsMasterDataTest(enumId:number) {
  this.paymentTermsDataSource = [];
  this.masterDataService.getCodeListData(enumId,'').subscribe(
    res=>{
      this.paymentTermsDataSource   =res.data;
    }
  );
 
};

paymentTermsDropdownBindingData=new PaymentTermsDropdownBinding();
paymentTermsDropdownBindingList:PaymentTermsDropdownBinding[]=[];
paymentTermsData : any[] = [];

GetPaymentTermsMasterData(enumId:number) {
 this.paymentTermsDataSource = [];
 this.paymentTermsDropdownBindingList=[];
 let paymentTermsData=[];
  this.masterDataService.getCodeListData(enumId,'').subscribe(
    res=>{
      this.paymentTermsData=res.data;
      this.paymentTermsData.forEach(item => {
            this.paymentTermsDropdownBindingData=new PaymentTermsDropdownBinding();
            this.paymentTermsDropdownBindingData.id=item.id;
            this.paymentTermsDropdownBindingData.name=item.name;
            this.paymentTermsDropdownBindingData.label='[' + item.code + '] ' + item.name;
            this.paymentTermsDropdownBindingList.push(this.paymentTermsDropdownBindingData);
      });    
      this.paymentTermsDataSource =this.paymentTermsDropdownBindingList;
    });
}

GetFunctionsMasterData(enumId:number) {
  this.functionsDataSource = [];
  this.masterDataService.getCodeListData(enumId,'').subscribe(
    res=>{
      this.functionsDataSource   =res.data;
    }
  );
 
};
totalSponsorContacts:any;
getSponserData(){
 let selectedSponsorId = this._engagement.sponsorId
  this.masterDataService.getSponserData(selectedSponsorId).subscribe(res=>{
    this.sponsorContactsDataSource= res.data.sponserContacts;
  });
}

selectedFunction:string='';
OnChangeFunction(data:any){
  let selectedFunctionId =data.value;
  this._supplierMSA.functionId = selectedFunctionId;

  const selectedSponsorFunction = this.functionsDataSource.find(x => x.id === selectedFunctionId);
    this.selectedFunction = selectedSponsorFunction ? selectedSponsorFunction.name : '';

}
OnChangePaymentterm(data:any){
  let selectedFunctionId =data.value;
 
}
_supplierMSA:any;
_msaSupplier:any;
_msaSponsor:any;

setMSA_StatusDefaultValue(){

  if(this.msaStatusDataSource?.length>0){
    let filteredStatus= this.msaStatusDataSource?.filter(t=>t.code=='ATV');
    if(filteredStatus?.length>0) 
    this._supplierMSA.statusMappingId=filteredStatus[0]?.id; 
     // display the MSA popu
    this.displayMSADialog = true;
  }
      setTimeout(() => {
        }, 100);
}
showAddMSADialog() { 
  if(this._supplierMSADetails?.length<5)
  {
    this.resetMSAForm();
    this.minEndDate=null;
    this.maxStartDate=null;
    this.msaPopHeader='Add MSA';
    this.isEditMSA=false;
    this.GetPaymentTermsMasterData(6); // call get payment terms and bind into the dropdown
    this.GetFunctionsMasterData(5); // call get function and bind into the dropdown
    this.GetMSAStatusMasterData(3, 'Master Service Agreement');// call get msa status and bind into the dropdown
   // this.getSponserData(); // get sponsor contact details by passing selected sponsor Id and bind into the dropdown
   if(this.allMsaSupplierContactsDataSource==undefined){
    this.allMsaSupplierContactsDataSource=this.supplierContactsDataSource;
   }
   if(this.allMsaSponsorContactsDataSource==undefined){
    this.allMsaSponsorContactsDataSource= this.sponsorContactsDataSource;
   }
    this.updateMsaContactLists('sponsor');
    this.updateMsaContactLists('supplier');

  }
  else{
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'Maximum of 5 MSA allowed!',
      detail: '',
  });
  }
}
resetMSAForm(){
  this.msaSaved = false;
  this._supplierMSA= new SuppliersMasterServiceAgreement();
  this._msaSupplier= new SupplierContact(); 
  this._msaSponsor=new SponsorContact();
  
  this.supplierMSAForm.patchValue({
    _msaFileName: '',
    _msafileSource: ''
})
 }
 cancelMSADialog(isCancelButton:boolean){
  // close MSA popup
  this.displayMSADialog = false;
  this.resetMSAForm();
  if(isCancelButton)
  {
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'MSA Information has not been saved',
      detail: '',
  });
  }
}


currentEditMSAIndex:number=0;
oldSponsorId:number=0;
oldSupplierId:number=0;
showEditMSADialog(item:any,currentIndex:number){
this.resetMSAForm();
this.minEndDate=null;
this.maxStartDate=null;
this.msaPopHeader='Edit MSA';
this.isEditMSA=true;
this.oldSupplierId=item.supplierContactId;
this.oldSponsorId=item.sponsorContactId;

this.msaSponsorContactsDataSource = this.sponsorContactsDataSource.filter(item1 => {
  return this.stakeholderSponsorcontactData.some(item2 => item2.id === item1.id);
});

this.msaSupplierContactsDataSource = this.supplierContactsDataSource.filter(item1 => {
  return this.stakeholderSuppliercontactData.some(item2 => item2.id === item1.id);
});



    this.GetPaymentTermsMasterData(6); // call get payment terms and bind into the dropdown
    this.GetMSAStatusMasterData(3, 'Master Service Agreement');// call get msa status and bind into the dropdown
    this.GetFunctionsMasterData(5); // call get function and bind into the dropdown
   // this.getSponserData(); // get sponsor contact details by passing selected sponsor Id and bind into the dropdown

this.selectedMSAItem = { ...item }; 
this.oldSelectedMSAItem={ ...item }; 
this.currentEditMSAIndex=currentIndex;
this._supplierMSA=this.selectedMSAItem;
 let filterSponsorContactData = this.sponsorContactsDataSource?.filter(t=>t.id==this._supplierMSA.sponsorContactId);
 if(filterSponsorContactData?.length>0)
  this._msaSponsor = filterSponsorContactData[0];
  let filterSupplierContactData = this.supplierContactsDataSource?.filter(t=>t.email==this._supplierMSA.supplierContactEmail && t.contactName==this._supplierMSA.supplierContactName); // have to change the logic for supplierContactId
  if(filterSupplierContactData?.length>0)
  this._msaSupplier = filterSupplierContactData[0];


this.supplierMSAForm.patchValue({
  _msaFileName: this._supplierMSA.fileName,
  _msafileSource: this._supplierMSA.filePath
});

this.displayMSADialog = true;

}


saveMSA(){
  // push the create MSA data into the common model to save/update the changes on the database
      this.msaSaved = true;
      this.isSavePopupBtnDisable = true;
      setTimeout(() => {
        this.isSavePopupBtnDisable = false;
      }, 3000);
     if (this.supplierMSAForm.valid) {
      this.msaSponsorContactDetails(this._supplierMSA.sponsorContactId);
      this.msaSupplierContactDetails(this._supplierMSA.supplierContactId);
      this.saveMSADetails();
     }
     else{
       this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
     }
  }

  msaSponsorContactDetails(id: any) {
    if (id) {
      this.excludedSponsorContactIds.push(id);
      this.sponsorContactsDataSource = this.sponsorContactsDataSource.filter(contact => contact.id !== id);

    }
  }

  totalSupplierContacts:any;
  msaSupplierContactDetails(id: any) {
    if (id) {
      this.excludedSupplierContactIds.push(id);
      this.supplierContactsDataSource = this.supplierContactsDataSource.filter(contact => contact.id !== id);
    }
  }

 uploadedMultipleMSAFileData : any[]=[]; 
 file_size=0;
 maxFileSize:any
 detail:string="";
 fileExist:boolean=false;
 onMSAfileAttachmentChange(fileDetails:any) {
  let uploadedMSAFile: any;

  this.maxFileSize=this.fileUploadService._fileSize; // 20 MB  (value Bytes  in binary)
    // file upload on change event method
       if(fileDetails?.length > 0) 
       {
          let currentFileSize=fileDetails[0].size;
          if(currentFileSize<=this.maxFileSize)
          {
            uploadedMSAFile = fileDetails[0];
            let uploadedExtension = uploadedMSAFile.name.split('.').pop().trim().toLowerCase();
           this.fileExist= this.IsCheckFileDuplicate(uploadedMSAFile.name);
           if(this.fileExist)
           {
            this.detail="File name (including extension) should be unique under this particular Engagement.";
           }
           else
           {
            this.detail="Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.";
           }
            if(this.fileUploadService.allowedExtensions.includes(uploadedExtension) && !this.fileExist)   
            { 
                this.supplierMSAForm.patchValue({
                  _msaFileName: uploadedMSAFile.name,
                })

            
              this.timeStamp ="";
              let utcDateTimeNow =  new Date().toISOString()
              this.timeStamp = formatDate(utcDateTimeNow, 'ddMMyyyy-hhmmss', 'en-US');
              // update File name / file relative path for UI and DB 
              this._supplierMSA.uploadedMSAFile = uploadedMSAFile;
              this._supplierMSA.fileName = uploadedMSAFile.name;
              this._supplierMSA.filePath ="MSAs/"+this.timeStamp+"_"+this._supplierMSA.fileName;
           }
            else{
              this.supplierMSAForm.patchValue({
                _msaFileName: '',
                _msafileSource: ''
            })
            this._supplierMSA.fileName='';
                this.messageService.add({  key: 'tc',severity: 'warn', summary: this.detail, detail: ''});
                // life:500 - life - Number of time in milliseconds to wait before closing the message.
            }
      }
      else{
         this.supplierMSAForm.patchValue({
           _msaFileName: '',
           _msafileSource: ''
       })
       this._supplierMSA.fileName='';
       this.messageService.add({  key: 'tc',severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
     }
     }
}


IsCheckFileDuplicate(value: string): boolean {


  if (this.oldSelectedMSAItem?.fileName === value) {
    return false;
  }
  return this._supplierMSADetails.some(obj => obj.fileName === value);
}

contactUniqueMessage:string="";
IsCheckDuplicateContact(name: any,contractType:number): void {


if(contractType==1)//sponsor contact
{
  this.contactUniqueMessage="Contact Name already exists in the Sponsor of the selected Engagement. Please enter a unique Name";
  this.iscontactExist= this.sponsorContactsDataSource.some(obj=>obj.name?.toLowerCase()===name.toLowerCase())
}
else{//supplier contact
  this.contactUniqueMessage="Contact Name already exists in the Supplier of the selected Engagement. Please enter a unique Name";
  this.iscontactExist= this.supplierContactsDataSource.some(obj=>obj.contactName?.toLowerCase()===name.toLowerCase())
}
}
  

timeStamp:any;
saveMSADetails()  
{  
// update the PaymentTerm and Status for UI 
                 let selectedPaymentTerm = this.paymentTermsDataSource?.filter(t=>t.id==this._supplierMSA.paymentTermId);
                if(selectedPaymentTerm.length>0 && selectedPaymentTerm[0].name != undefined)
                this._supplierMSA.paymentTerm =selectedPaymentTerm[0].name;
                let selectedMSAStatus = this.msaStatusDataSource?.filter(t=>t.id==this._supplierMSA.statusMappingId);
                if(selectedMSAStatus.length>0 && selectedMSAStatus[0].name != undefined)
                this._supplierMSA.msaStatus =selectedMSAStatus[0].name;

       if(this.isEditMSA)
       {
              
                this._supplierMSADetails[this.currentEditMSAIndex] = { ... this._supplierMSA };
                this.selectedMSAItem = null;
                this.oldSelectedMSAItem=null;
                this._supplierMSA= null;
                this.currentEditMSAIndex=0;
            
        } 
        else
        {
           this._supplierMSADetails.push(this._supplierMSA);
        } 
          //close the create MSA popup
          this.cancelMSADialog(false);
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'MSA Information has been saved', detail: '' });

}
deleteMSA(item: any) {
    this.selectedMSAItem = item;
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this MSA?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.deleteMSAItem();
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'MSA has been deleted',
            detail: '',
        });
        },
        reject: (type: any) => {
           
        }
    });
}

deleteMSAItem() {
  const msaIndex = this._supplierMSADetails.findIndex(item => item === this.selectedMSAItem);
  if (msaIndex !== -1) {
    this._supplierMSADetails.splice(msaIndex, 1);
    this.selectedMSAItem = null;
  }
}

deleteMSAObject:any[]=[];
deleteMSAFileObject(){ 

  if(this.isEditMSA)
  {
    let filedetail1 = {
    "fileName": this._supplierMSA.fileName,
    "filePath": this._supplierMSA.filePath
    };
    this.supplierMSAForm.patchValue({
      _msaFileName: '',
      _msafileSource: ''
  })
    this.deleteMSAObject.push(filedetail1);
  }

    this._supplierMSA.fileName = "";
    this._supplierMSA.filePath ="";
    this._supplierMSA.uploadedMSAFile=null;
    this.supplierMSAForm.patchValue({
      _msaFileName: '',
      _msafileSource: ''
    })      
}

onStartDateSelection(startDate:any){
  this.minEndDate= new Date(startDate);
  this.minEndDate.setDate(this.minEndDate.getDate() + 1);
}
  
onEndDateSelection(endDate:any){
    this.maxStartDate= new Date(endDate);
    this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
}

canExist(): boolean | Promise<boolean> | Observable<boolean> {

  this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);

    if (this.executeCanExist &&!(this._oldObject==this._newObject))
    {
      return new Promise<boolean>((resolve) => {
        this.confirmationService.confirm({
          message:this. _message,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: 'info',
              detail: this._details,
          });
            resolve(true); 
          },
          reject: () => {
            resolve(false); 
          },
        });
      });
    }
    else{
      return true;
    }
}


  generateBreadcrumb()
  {
      this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.CENG);
    
  }

  generateBreadcrumbforEdit(component?:string,recordName?:string,tabName?:string)
  {
    this.breadcrumbService.generateBreadcrumb(component,recordName,tabName);
  }

  getBreadcrumbs(): MenuItem[] {
    return this.breadcrumbService.getBreadcrumbs();
  }
  GetEngagementStatus(){
    this.masterDataService.getCodeListData(3,'Engagement').subscribe(
      data=>{
        this.statusData=data.data;
        
        if(!this.isEditPage){
          this.defaultstatus=this.statusData.filter((x:any)=>x.name=ActiveInActiveStatus.Active);
          this._engagement.statusMappingId= this.defaultstatus[0].id;
        }
        
      }
    );
   
  }
  onOptionChange(event:any): void { 
    this._engagement.statusMappingId=event.value;

    //Check if status is Inactive then search for active contracts
    if(this.isEditPage){
      this.loaderService.showLoader();
      let status=this.statusData.filter((aa:any)=> aa.id==event.value);
      if(status[0].name=="InActive"){

        //Call get all contract API
        this.GetAllContractByEngagementId();
      }
      else{
        this.loaderService.hideLoader();
      }
    }

    }


    contractdata:any;
    listOfActiveContract:any;
    countOfActiveContract:any;
    makeContractInActive=false;
    GetAllContractByEngagementId(){

      this._contractService.GetAllContractByEngagementId(this.engagementId).subscribe(
        res => {
          this.loaderService.hideLoader();
          this.contractdata = res.data;
          
          
          let activeCount = 0;

          this.contractdata.forEach((contract:any) => {
          if (contract.statusName === 'Active') {
            
            activeCount++;
            }
            });

           
           

           if(activeCount>0){
           this.confirmationService.confirm({
            message: 'This Engagement has ' +  activeCount + ' Contract(s) linked under it, do you still want to make it inactive? This will result in making any contracts under it being made inactive as well.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.makeContractInActive=true;
            },
            reject: (type: any) => {
              let activeStatusDetails=this.statusData.filter((aa:any)=> aa.name=='Active');
              this._engagement.statusMappingId=activeStatusDetails[0].id;
              this.makeContractInActive=false;
            }
        });
      }
  
  
        });
    }

    prepareBlankSupplierInfoObject=new SupplierProfile();
    prepareBlankSupplierContactInfoObject=new SupplierContact();
    prepareBlankSupplierMSAInfoObject=new SuppliersMasterServiceAgreement()

 
    changedSupplierBasicInfo:any;
    changedSupplierContactInfo:any;
    changedSponsorContactInfo:any;
    changedSupplierMSAInfo:any;
    listChangedSponsorBasicContactInfo:any;
    auditModelForSponser=new AuditLogModel();
    auditModelForSponserContact=new AuditLogModel();
    compareModels() {

      let operationName="";
      if(!this.isEditPage){
        operationName=this.auditOperations.Create;
        this.oldEngagementData=JSON.stringify(new EngagementPostModel());
        this.oldEngagementSponsorContact=JSON.stringify(new StakeholderContacts());
        this.oldEngagementSupplierContact=JSON.stringify(new StakeholderContacts());
        this.oldEngagementMSA=JSON.stringify(new EngagementMasterServiceAgreement());
      }
      else{
        operationName=this.auditOperations.Update;
      }
      this.oldEngagementData=JSON.parse(this.oldEngagementData);
      this._engagement.engagementStartDate= new Date(this._engagement.engagementStartDate).toLocaleDateString(); 
     
     let sponsorContactNewValue;
     let sponsorContactOldValue;
     sponsorContactNewValue = this.stakeholderSponsorContacts.map((contact:any) => contact.id);
    
     
     let supplierContactNewValue;
     let supplierContactOldValue;
     supplierContactNewValue = this.stakeholderSupplierContacts.map((contact:any) => contact.id);
     
     this._engagement.supplierForAudit=supplierContactNewValue;
     this._engagement.sponsorForAudit=sponsorContactNewValue;

  
     if(this.isEditPage){
      this.oldEngagementData.engagementStartDate= new Date(this.oldEngagementData.engagementStartDate).toLocaleDateString(); 

      supplierContactOldValue=JSON.parse(this.oldEngagementSupplierContact).map((contact:any) => contact.supplierContactId);
      sponsorContactOldValue=JSON.parse(this.oldEngagementSponsorContact).map((contact:any) => contact.sponsorContactId);

      this.oldEngagementData.supplierForAudit=supplierContactOldValue;
      this.oldEngagementData.sponsorForAudit=sponsorContactOldValue;
 
     }
      
     this.changedSupplierBasicInfo = this.auditLogService.compareModels(this.oldEngagementData,this._engagement,this.auditEntities.EngagementDetails,this.auditIdentifierDetails.EngagementDetails,operationName, this.auditModule.SupplierService);
     


      

      this._supplierMSA= new SuppliersMasterServiceAgreement();
   
      this._supplierMSADetails.forEach((item:any) => {
        item.startDate= new Date(item.startDate).toLocaleDateString(); 
        item.endDate= new Date(item.endDate).toLocaleDateString(); 
       
        if(this.isEditPage){
        item.updatedBy=this._engagement.updatedBy;
        item.updatedDate=this._engagement.updatedDate;
        }
        else{
          item.createdBy=this._engagement.createdBy;
          item.createdDate=this._engagement.createdDate;
        }
        if(item.id != 0 && !(item.isDeleted))//Edit case
        {
            let oldContactInfo=JSON.parse(this.oldEngagementMSA).filter((aa:any)=>aa.id==item.id);
           if(oldContactInfo.length>0){

            oldContactInfo[0].startDate = new Date(oldContactInfo[0].startDate).toLocaleDateString();
            oldContactInfo[0].endDate = new Date(oldContactInfo[0].endDate).toLocaleDateString();;
           
            

            this.changedSupplierMSAInfo=this.auditLogService.compareModels(oldContactInfo[0],item,this.auditEntities.EngagementMSA,this.auditIdentifierDetails.EngagementMSA,operationName,this.auditModule.EngagementService);
           }
        }
        else if(item.id != 0 && item.isDeleted)//DeleteContact Case
        {
          this.changedSupplierMSAInfo=this.auditLogService.compareModels(item,JSON.parse(this.blankEngagementMSA),this.auditEntities.EngagementMSA,this.auditIdentifierDetails.EngagementMSA,operationName,this.auditModule.EngagementService);
        }
        else//new contact
        {
          this.changedSupplierMSAInfo=this.auditLogService.compareModels(JSON.parse(this.blankEngagementMSA),item,this.auditEntities.EngagementMSA,this.auditIdentifierDetails.EngagementMSA,operationName,this.auditModule.EngagementService);
        }
      });


    } 
    selectedSponsorContactsState: { id: any, index: number }[] = [];
   


  sponsorContactDetails(event: any, index: number) {
    const selectedSponsorContactId = event.value;
  
    if (selectedSponsorContactId) {
      const selecctedSponsor = this.stakeholderSponsorContacts.some(contact => contact?.id === selectedSponsorContactId);
      if (selecctedSponsor) {
        this.messageService.add({
          key: 'tc',
          severity: 'warn',
          summary: 'Contact already selected.',
          detail: '',
        });
  
        setTimeout(() => {
          this.stakeholderSponsorcontactData[index].id = null;
        }, 0);
        event.value = null;
        return; 
      }
      const selectedSponsorContact = this.sponsorContactsDataSource.find(contact => contact.id === selectedSponsorContactId);
  
      if (selectedSponsorContact) {
        this.stakeholderSponsorContacts[index] = selectedSponsorContact;
         this.updateMsaContactLists('sponsor');
    }
    } 
    else {
      this.stakeholderSponsorContacts[index] = null;
    }
  }

  updateFilteredLists() {
    const selectedIds = this.selectedStakeholderSponsorContacts.filter(id => id); // Get all selected IDs
    this.filteredSponsorContacts = this.sponsorContactsDataSource.filter(contact => !selectedIds.includes(contact.id));
    this.filteredMsaSponsorContacts = this.allMsaSponsorContactsDataSource.filter((contact: { id: any; }) => !selectedIds.includes(contact.id));
}


  selectedStakeholderSponsorContacts: any[] = [];
  filteredMsaSponsorContacts: any[] = [];

   addContactBackToLists(contactId: any) {
     if (contactId) {
         if (!this.filteredSponsorContacts.some(contact => contact.id === contactId)) {
             const contactToAdd = this.sponsorContactsDataSource.find(contact => contact.id === contactId);
             if (contactToAdd) this.filteredSponsorContacts.push(contactToAdd);
         }

         if (!this.filteredMsaSponsorContacts.some(contact => contact.id === contactId)) {
             const contactToAdd = this.allMsaSponsorContactsDataSource.find((contact: { id: any; }) => contact.id === contactId);
             if (contactToAdd) this.filteredMsaSponsorContacts.push(contactToAdd);
         }
     }
   }
   
    selectedSupplierContactId: any;
    selectedSupplierContactDetails: any;
   

    supplierContactDetails(event: any, index: number) {
      const selectedSupplierContactId = event.value;
    
      if (selectedSupplierContactId) {
        const selecctedSupplier = this.stakeholderSupplierContacts.some(contact => contact?.id === selectedSupplierContactId);
        if (selecctedSupplier) {
          this.messageService.add({
            key: 'tc',
            severity: 'warn',
            summary: 'Contact already selected.',
            detail: '',
          });
    
          setTimeout(() => {
            this.stakeholderSuppliercontactData[index].id = null;
          }, 0);
          event.value = null;
          return; 
        }
        const selectedSupplierContact = this.supplierContactsDataSource.find(contact => contact.id === selectedSupplierContactId);
    
        if (selectedSupplierContact) {
          this.stakeholderSupplierContacts[index] = selectedSupplierContact;
          this.updateMsaContactLists('supplier');
        }
      } else {
        this.stakeholderSupplierContacts[index] = null;
      }
    }
  
    isCheckSupplierContactName(Id: number): boolean {
     
      if (this.stakeholderSupplierContacts.length==0 || this.stakeholderSupplierContacts[0]==null) {
          return false;
      }
      else
      {
      return this.stakeholderSupplierContacts.some(obj => obj.id === Id);
      }
  }
   


    stakeholderSponsorcontactData:any[]=[];
    AddStakeholderSponsorContact(){ 
      this.stakeholderSponsorcontactData.unshift({
        id: 0,
        name: '',
        designation:'',
        countrycode: '',
        phonenumber:'',
        isSendnotification:false,
        isdeleted:false
      
      });
      this.stakeholderSponsorcontactData.forEach((item, index) => {
        this.sponsorContactDetails({ value: item.id }, index);
      });
    }

    stakeholderSuppliercontactData:any[]=[];
  AddStakeholderSupplierContact(){ 

   
    this.stakeholderSuppliercontactData.unshift({
      id: 0,
      name: '',
      designation:'',
      countrycode: '',
      phonenumber:'',
      isSendnotification:false,
      isdeleted:false
    
    });
    this.stakeholderSuppliercontactData.forEach((item, index) => {
      this.supplierContactDetails({ value: item.id }, index);
    });
  }

  currentHeader:number=0;
    openContactModel(header: number) {
      this.currentHeader=header;
      this.iscontactExist=false;
      this.contactHeader=header==1?'Add Sponsor Contact':'Add Supplier Contact'
      if (this._engagement.supplierId!==null && this._engagement.sponsorId) {
        this._contact=new Contact();
        this.contactSubmitted = false;
    
        // Create a new instance of sponserContact
        this.stakeholderContact = {
          name: "",
          designation: "",
          countryCode: "",
          phoneNumber: "",
          email: ""
        };
    
        this.displayDialog = true;
      } else {
        this.messageService.add({
          key: 'tc',
          severity: 'warn',
          summary: 'Please select sponsor and supplier.',
          detail: '',
        });
      }
    }
    validateAlphaCharacters(control: AbstractControl): ValidationErrors | null {
      const value = control.value;
  
      
      const pattern = /^[\S\s]*$/;
  
      if (value && !pattern.test(value)) {
        return { invalidAlphaCharacters: true };
      }
  
      return null;
    } 


    GetAllsupplierContacsById(supplierId:number=0) {
   
      this.supplierService.GetSupplierById(supplierId).subscribe(res => {
          this.supplierContactsDataSource=res.data.supplierContacts;
          this.filteredSupplierContacts = [...this.supplierContactsDataSource];
          this.totalSupplierContacts=res.data.supplierContacts;
          this.updateMsaContactLists('supplier');
          this.excludeContact();
      });
    
    }
    GetAllSponsorContactsById(sponsorId:number=0) {
      this.masterDataService.getSponserData(sponsorId).subscribe(res=>{
        this.sponsorContactsDataSource= res.data.sponserContacts;
        this.totalSponsorContacts=res.data.sponserContacts;
        this.filteredSponsorContacts = [...this.sponsorContactsDataSource];
        this.updateMsaContactLists('sponsor');
        this.excludeContact();
      });
    }
  
     
  //---------------Edit related Code---------------------
  CreatedUserName:any;
 defaultCurrency:any;
  BindEngagementModel(){
  
    this.loaderService.showLoader();
   
    this.GetPaymentTermsMasterData(6);
   
    this.isEditMSA=true;
    this.GetMSAStatusMasterData(3, 'Master Service Agreement');
 
  
    this.engagementService.GetEngagementById( this.engagementId).subscribe
    (res => {
        this._engagement=res.data;
        this.engagementName=this._engagement.engagementName;
        this.engagementStartDate=this._engagement.engagementStartDate;
        this.SupplierId=this._engagement.supplierId;
        this.SponsorId=this._engagement.sponsorId;
        this.GetAllsupplierContacsById(this._engagement.supplierId);
        this.GetAllSponsorContactsById(this._engagement.sponsorId);
        
        setTimeout(() => {
        this.BindEditFields();
      }, 1000);

      // setTimeout(() => {
      if(this.currentTab && this.currentTab.tabName=="Contracts"){
        this.ParentTabSelect(this.currentTab.tabId);
        this.tabAndStateService.setPreviousState('',0);
      }
   // }, 1000);
       
  });
  setTimeout(() => { 
    this.loaderService.hideLoader();
  }, 4000);
}

BindEditFields(){

  this.allMsaSponsorContactsDataSource = this.sponsorContactsDataSource.filter(
    sponsor => !this.stakeholderSponsorContacts.some(contact => contact.id === sponsor.id)
  );

  // Filtering MSA supplier contacts
  this.allMsaSupplierContactsDataSource = this.supplierContactsDataSource.filter(
    supplier => !this.stakeholderSupplierContacts.some(contact => contact.id === supplier.id)
  );

  this.oldEngagementData=JSON.stringify(this._engagement);
  this.oldEngagementSupplierContact=JSON.stringify(this._engagement.supplierContacts);
  this.oldEngagementSponsorContact=JSON.stringify(this._engagement.sponsorContacts);
  this.oldEngagementMSA=JSON.stringify(this._engagement.engagementMasterServiceAgreements);
  this.CreatedUserName=this.privilegeService.getUserName(this._engagement.createdBy);

  this.generateBreadcrumbforEdit(this._enumBreadcrumb.EVENG,this._engagement.engagementName,"Profile");
  this._engagement.engagementStartDate=new Date(this._engagement.engagementStartDate);
 


this.defaultCurrency  = this.currencyCodeList?.find(t => t.id == this._engagement.defaultCurrency)?.name;
 


    // This code will execute only after both promises are resolved
    // Add your other logic here
setTimeout(() => { 
    if(this._engagement.sponsorContacts.length>0){
      this._engagement.sponsorContacts.forEach((item:any, index:any) => {
        this.stakeholderSponsorcontactData.push({
          id:  item.sponsorContactId,
          name: '',
          designation:'',
          countrycode: '',
          phonenumber:'',
          isSendnotification:item.isSendNotification,
          isdeleted:false,
          sponsorContactId:item.sponsorContactId,
          sendNotificationOptions:item.sendNotificationOptions,
        
        });

       // setTimeout(() => { 
        this.sponsorContactDetails({ value: item.sponsorContactId }, index);
    //  }, 3000);
      });
    }

   

      //Supplier Grid
     
     
        if(this._engagement.supplierContacts.length>0){
          this._engagement.supplierContacts.forEach((item1:any, index:any) => {
      this.stakeholderSuppliercontactData.push({
        id:  item1.supplierContactId,
        name: '',
        designation:'',
        countrycode: '',
        phonenumber:'',
        isSendnotification:item1.isSendNotification,
        isdeleted:false,
        supplierContactId:item1.supplierContactId,
        sendNotificationOptions:item1.sendNotificationOptions,
      });
    
     // setTimeout(() => { 
      this.supplierContactDetails({ value: item1.supplierContactId }, index);
  // }, 3000);
   
  });
    }


 
    
    if (this._engagement.engagementMasterServiceAgreements !== undefined) {
    this._supplierMSADetails=this._engagement.engagementMasterServiceAgreements;
    this.excludeContact();
    }
    this._supplierMSADetails.forEach((item:any)=>{
      item.startDate=new Date(item.startDate);
      item.endDate=new Date(item.endDate);
      let selectedPaymentTerm = this.paymentTermsDataSource?.filter(t=>t.id==item.paymentTermId);
      if(selectedPaymentTerm.length>0 && selectedPaymentTerm[0].name != undefined)
      item.paymentTerm=selectedPaymentTerm[0].name;

      let selectedMSAStatus = this.msaStatusDataSource?.filter(t=>t.id==item.statusMappingId);
      if(selectedMSAStatus.length>0 && selectedMSAStatus[0].name != undefined)
      item.msaStatus =selectedMSAStatus[0].name;
    });

 
  this.onSupplierChange();
  this.oldEngagementData = typeof this.oldEngagementData === 'string' ? JSON.parse(this.oldEngagementData) : this.oldEngagementData;
this.oldEngagementData.engagementMasterServiceAgreements=this._supplierMSADetails;
this.oldEngagementData=JSON.stringify(this.oldEngagementData);
 
}, 1000);

  

}


  downloadMSAFile(fileName:any,filePath:any){
    let filedetail = {
      "fileName": fileName,
      "filePath": filePath
  };
  this.fileUploadService.DownloadFile(filedetail).subscribe(
      res => {
          let test = res;
          let blobData = this.fileUploadService.dataURItoBlob(res);
          this.fileUploadService.downloadBase64File(blobData, filedetail.fileName);
      }
  );
  }

  isSponsorExistOnMSA=false;
  deleteSponsorContact(index:any){
    this.loaderService.showLoader();

    //Check contact on MSA
  
   let checkContactExistenceOnMSA=this._supplierMSADetails.filter(aa=>aa.sponsorContactId==this.stakeholderSponsorcontactData[index].id);
   
   
  if(checkContactExistenceOnMSA.length>0 && this.stakeholderSponsorContacts[index].id != 0)
    {
      this.isSponsorExistOnMSA=true;
    }
    else{
      this.isSponsorExistOnMSA=false;
    }

if(!this.isEditMSA)
  {
    if(this.stakeholderSponsorcontactData[index].id){
      this.loaderService.hideLoader();
     this.confirmationService.confirm({
        message: 'Are you sure you want to delete this stakeholder from this Engagement? Note: The stakeholder will only be deleted from this engagement and will still stay under the Sponsor Contacts.',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.stakeholderSponsorcontactData.splice(index, 1);
          this.stakeholderSponsorContacts.splice(index, 1);
          
        },
        reject: (type: any) => {
        
        }
    });
     }
     else{
      this.loaderService.hideLoader();
      this.stakeholderSponsorcontactData.splice(index, 1);
      this.stakeholderSponsorContacts.splice(index, 1);
     }
  }
else{
this.CheckSponsorContactONContract(this.stakeholderSponsorcontactData[index].id,index);
}
}

isSponsorExistIOnContract=false;
  CheckSponsorContactONContract(SponsorContactId:any,sponsorIndex:number){
    this._contractService.GetAllContractByEngagementId(this.engagementId).subscribe(
      res => {
        this.contractdata = res.data;

        let activeTitles: string[] = [];
        this.contractdata.forEach((contract:any) => {

          let isExist= this.contractdata.filter((aa:any)=>aa.sponserContactId==SponsorContactId);
          if (isExist.length>0) {
            activeTitles.push(contract.title);
           }
         
            });

       let commaSeparatedTitles="";
       if(activeTitles.length>0){
           commaSeparatedTitles = activeTitles.join(', ');
           this.isSponsorExistIOnContract=true;
       }
       else{
        this.isSponsorExistIOnContract=false;
       }


           if(this.isSponsorExistOnMSA==true &&  this.isSponsorExistIOnContract==true)
            {
              this.messageService.add({  key: 'tc',severity: 'warn', life: 8000 ,summary: 'This contact is selected as the Owner on the Contract(s) "' + commaSeparatedTitles + '" and also on an MSA. It cannot be deleted', detail: '' })
              this.loaderService.hideLoader();
           }
           else if(this.isSponsorExistOnMSA==true &&  this.isSponsorExistIOnContract==false)
            {
              this.messageService.add({  key: 'tc',severity: 'warn',life: 8000, summary: 'This contact is selected on an MSA and cannot be deleted', detail: '' })
              this.loaderService.hideLoader();
           }
           else if(this.isSponsorExistOnMSA==false &&  this.isSponsorExistIOnContract==true)
            {
              this.messageService.add({  key: 'tc',severity: 'warn', life: 8000, summary: 'This contact is selected as the Owner on the Contract(s) "' + commaSeparatedTitles + '" and cannot be deleted', detail: '' })
              this.loaderService.hideLoader();
           }
           else if(this.stakeholderSponsorcontactData[sponsorIndex].id){
            this.loaderService.hideLoader();
           this.confirmationService.confirm({
              message: 'Are you sure you want to delete this stakeholder from this Engagement? Note: The stakeholder will only be deleted from this engagement and will still stay under the Sponsor Contacts.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.stakeholderSponsorcontactData.splice(sponsorIndex, 1);
                this.stakeholderSponsorContacts.splice(sponsorIndex, 1);
                
              },
              reject: (type: any) => {
              
              }
          });
           }
           else{
            this.loaderService.hideLoader();
            this.stakeholderSponsorcontactData.splice(sponsorIndex, 1);
            this.stakeholderSponsorContacts.splice(sponsorIndex,1);
           }

      });

  }

  isSupplierExistOnMSA=false;

  deleteSupplierContact(index:any){

    this.loaderService.showLoader();

    //Check contact on MSA
  
   let checkContactExistenceOnMSA=this._supplierMSADetails.filter(aa=>aa.supplierContactId==this.stakeholderSuppliercontactData[index].id);
   
   
  if(checkContactExistenceOnMSA.length>0 && this.stakeholderSupplierContacts[index].id != 0)
    {
      this.isSupplierExistOnMSA=true;
    }
    else{
      this.isSupplierExistOnMSA=false;
    }
    if(!this.isEditMSA){
      if(this.stakeholderSuppliercontactData[index].id){
        this.loaderService.hideLoader();
       this.confirmationService.confirm({
          message: 'Are you sure you want to delete this stakeholder from this Engagement? Note: The stakeholder will only be deleted from this engagement and will still stay under the Supplier Contacts.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.stakeholderSuppliercontactData.splice(index, 1);
            this.stakeholderSupplierContacts.splice(index, 1);
            
          },
          reject: (type: any) => {
          
          }
      });
       }
       else{
        this.loaderService.hideLoader();
        this.stakeholderSuppliercontactData.splice(index, 1);
        this.stakeholderSupplierContacts.splice(index, 1);
       }
    }
    else{
    this.CheckSupplierContactONContract(this.stakeholderSuppliercontactData[index].id,index);
    }  
  }

isSupplierExistIOnContract=false;
CheckSupplierContactONContract(SupplierContactId:any,supplierIndex:number){
    this._contractService.GetAllContractByEngagementId(this.engagementId).subscribe(
      res => {
        this.contractdata = res.data;

        let activeTitles: string[] = [];
        this.contractdata.forEach((contract:any) => {

          let isExist= this.contractdata.filter((aa:any)=>aa.ownerSupplierContactId==SupplierContactId);
          if (isExist.length>0) {
            activeTitles.push(contract.title);
           }
         
            });

       let commaSeparatedTitles="";
       if(activeTitles.length>0){
           commaSeparatedTitles = activeTitles.join(', ');
           this.isSupplierExistIOnContract=true;
       }
       else{
        this.isSupplierExistIOnContract=false;
       }


           if(this.isSupplierExistOnMSA==true &&  this.isSupplierExistIOnContract==true)
            {
              this.messageService.add({  key: 'tc',severity: 'warn', life: 8000 ,summary: 'This contact is selected as the Owner on the Contract(s) "' + commaSeparatedTitles + '" and also on an MSA. It cannot be deleted', detail: '' })
              this.loaderService.hideLoader();
           }
           else if(this.isSupplierExistOnMSA==true &&  this.isSupplierExistIOnContract==false)
            {
              this.messageService.add({  key: 'tc',severity: 'warn',life: 8000, summary: 'This contact is selected on an MSA and cannot be deleted', detail: '' })
              this.loaderService.hideLoader();
           }
           else if(this.isSupplierExistOnMSA==false &&  this.isSupplierExistIOnContract==true)
            {
              this.messageService.add({  key: 'tc',severity: 'warn', life: 8000, summary: 'This contact is selected as the Owner on the Contract(s) "' + commaSeparatedTitles + '" and cannot be deleted', detail: '' })
              this.loaderService.hideLoader();
           }
           else if(this.stakeholderSuppliercontactData[supplierIndex].id){
            this.loaderService.hideLoader();
           this.confirmationService.confirm({
              message: 'Are you sure you want to delete this stakeholder from this Engagement? Note: The stakeholder will only be deleted from this engagement and will still stay under the Supplier Contacts.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.stakeholderSuppliercontactData.splice(supplierIndex, 1);
                this.stakeholderSupplierContacts.splice(supplierIndex, 1);
                
              },
              reject: (type: any) => {
              
              }
          });
           }
           else{
            this.loaderService.hideLoader();
            this.stakeholderSuppliercontactData.splice(supplierIndex, 1);
            this.stakeholderSupplierContacts.splice(supplierIndex,1);
           }

      });

  }

  handleCancelAddButtonClick(cancelButtonType:number)//common cancel child compoments method
{
 
  switch (cancelButtonType) {
    case 1:
      this._currentKpiChildComponent=1;//supplier KPI
        break;
    case 2:
      this._currentMeetingChildComponent=1;//supplier Meeting list
        break;
        case 4:

        this._currentTeamChildComponent=1;//List associate
               break;
   
}
    }


handleAuditAddButtonClick() {
  this._currentAuditChildComponent=2;
  this.currentChildComponent=this.childAuditCreateComponent;
 }

 handleAddButtonClick(addButtonType:number) {
  switch (addButtonType) {
    case 1:
      this._currentKpiChildComponent=2;
      this.currentChildComponent=this.childKpiCreateComponent;
        break;
    case 2:
      this._currentMeetingChildComponent=2;
      this.currentChildComponent=this.childMeetingCreateComponent;
        break;

        case 4://Teams

            //team tab
            this.actionType='Create';
            this._currentTeamChildComponent=2;//Add associate
            this.currentChildComponent=this.childTeamCreateEditComponent;
             break;

 
}

 }

handleAuditSaveAddButtonClick()
{
  this.clearFormCompareObjects();
  this._currentAuditChildComponent=4;
}

handleAuditCalcelAddButtonClick()
{
  this._currentAuditChildComponent=1;
}

// CommonTabClick(CurrentSubIntext: number, CurrentTabIndex: number = 0) {
//   this.clearFormCompareObjects();
//   switch (CurrentSubIntext) {
//     case 0:
//       this.setChildComponent(
//         this._currentKpiChildComponent,
//         this.childKpiCreateComponent,
//         this.childKpiEditComponent,
//         "KPI not added",
//         this._commonEditDetails,
//         "KPI will not be added, Do you want to Continue?",
//         this._commonEditMessage
//       );
//       break;
//     case 1:
//       this.setChildComponent(
//         this._currentMeetingChildComponent,
//         this.childMeetingCreateComponent,
//         this.childMeetingEditComponent,
//         "Meeting not added",
//         this._commonEditDetails,
//         "Meeting will not be added, Do you want to Continue?",
//         this._commonEditMessage,
//         this.childMeetingRecYesEditComponent
//       );
//       break;
//     case 2:
//       this.setChildComponent(
//         this._currentAuditChildComponent,
//         this.childAuditCreateComponent,
//         this.childAuditEditComponent,
//         "Audit not added",
//         this._commonEditDetails,
//         "Audit will not be added, Do you want to Continue?",
//         this._commonEditMessage
//       );
//       break;
//   }

//   if (CurrentTabIndex === 5) {
//     this.setTeamComponent();
//   }

//   if (this._oldObject !== this._newObject) {
//     this.isformObjectChange = true;
//   }
// }
CommonTabClick(subTabindex:number,tabindex:number)
{
  this.clearFormCompareObjects();
if(tabindex==0)
{
this._message='Changes will not be saved, Do you want to continue?';
this._details='Changes not saved';
this.oldEngagementData = typeof this.oldEngagementData === 'string' ? JSON.parse(this.oldEngagementData) : this.oldEngagementData;
if(this.isEditPage){
this.oldEngagementData.engagementStartDate= new Date(this.oldEngagementData.engagementStartDate); 
}
 this._oldObject=JSON.stringify(this.oldEngagementData); 
 this._newObject=JSON.stringify(this._engagement);
}

else if(tabindex==3)
{
  switch (subTabindex) {
    case 0:
      if(this._currentKpiChildComponent==2)
      {
        this. _details="KPI not added";
        this. _message="KPI will not be added, Do you want to Continue?";
        this.currentChildComponent=this.childKpiCreateComponent;
        this._oldObject=this.currentChildComponent.oldEngagementKPIData; this._newObject=JSON.stringify(this.currentChildComponent._engagementKpi);
      }
      else if(this._currentKpiChildComponent==3)
      {
        this._details=this._commonEditDetails;
        this._message=this._commonEditMessage;
        this.currentChildComponent=this.childKpiEditComponent;
        this._oldObject=this.currentChildComponent._oldEngagementKpi; this._newObject=JSON.stringify(this.currentChildComponent._engagementKpi);
      }
      break;
      case 1:
        if(this._currentMeetingChildComponent==2)
        {
          this. _details="Meeting not added";
          this. _message="Meeting will not be added, Do you want to Continue?";
          this.currentChildComponent=this.childMeetingCreateComponent;
          
          this._oldObject=this.currentChildComponent._oldEngagementMeeting;
           this._newObject=JSON.stringify(this.currentChildComponent.engagementMeetingDetails);
        }
        else if(this._currentMeetingChildComponent==3 )
        {
          this._details=this._commonEditDetails;
          this._message=this._commonEditMessage;
          this.currentChildComponent=this.childMeetingEditComponent;
          
          this._oldObject=this.currentChildComponent._oldEngagementMeeting; this._newObject=JSON.stringify(this.currentChildComponent.engagementMeetingDetails);
        }
        else if(this._currentMeetingChildComponent==5)
        {
          this._details=this._commonEditDetails;
          this._message=this._commonEditMessage;
          this.currentChildComponent=this.childMeetingRecYesEditComponent;
          this._oldObject=this.currentChildComponent._oldEngagementMeeting; this._newObject=JSON.stringify(this.currentChildComponent.engagementMeetingDetails);
        }
      break;
      case 2:
        if(this._currentAuditChildComponent==2)
        {
          this._details="Audit not added";
          this._message="Audit will not be added, Do you want to Continue?";
         this.currentChildComponent=this.childAuditCreateComponent;
          this._oldObject=this.currentChildComponent._oldEngagementAudit; this._newObject=JSON.stringify(this.currentChildComponent._engagementAudit);
        }
        else if(this._currentAuditChildComponent==3)
        {
          this._details=this._commonEditDetails;
          this._message=this._commonEditMessage;
          this.currentChildComponent=this.childAuditEditComponent;
          this._oldObject=this.currentChildComponent._oldEngagementAudit; this._newObject=JSON.stringify(this.currentChildComponent._engagementAudit);
        }
      break;
    }


 
}

else if(tabindex==5){
  if(this.actionType=="Create")
  {
    this._message='Associate will not be added, Do you want to Continue?';
    this._details='Associate not added';
  }
  else{
  this._message='Changes will not be saved, Do you want to continue?';
  this._details='Changes not saved';
  }
   if (this.childTeamCreateEditComponent) {
    this.currentChildComponent = this.childTeamCreateEditComponent;
    this._oldObject = this.childTeamCreateEditComponent.oldTeamAssociateModel;
    this._newObject = JSON.stringify(this.childTeamCreateEditComponent.teamAssociateData);
  } else {
    this._oldObject = "";
    this._newObject = "";
  }
}
if(this._oldObject!=this._newObject)
this.isformObjectChange=true;
}


// setChildComponent(
//   currentChildComponentType: number,
//   createComponent: any,
//   editComponent: any,
//   createDetail: string,
//   editDetail: string,
//   createMessage: string,
//   editMessage: string,
//   additionalEditComponent?: any
// ) {
//   if (currentChildComponentType === 2) {
//     this._detail = createDetail;
//     this._message = createMessage;
//     this.currentChildComponent = createComponent;
//     this._oldObject = this.currentChildComponent._oldEngagementKpi || this.currentChildComponent._oldEngagementAudit || this.currentChildComponent._oldEngagementMeeting;
//     this._newObject = JSON.stringify(this.currentChildComponent._engagementKpi || this.currentChildComponent._engagementAudit || this.currentChildComponent.engagementMeetingDetails);
//   } else if (currentChildComponentType === 3) {
//     this._detail = editDetail;
//     this._message = editMessage;
//     this.currentChildComponent = editComponent;
//     this._oldObject = this.currentChildComponent._oldEngagementKpi || this.currentChildComponent._oldEngagementAudit || this.currentChildComponent._oldEngagementMeeting;
//     this._newObject = JSON.stringify(this.currentChildComponent._engagementKpi || this.currentChildComponent._engagementAudit || this.currentChildComponent.engagementMeetingDetails);
//   } else if (currentChildComponentType === 5 && additionalEditComponent) {
//     this._detail = editDetail;
//     this._message = editMessage;
//     this.currentChildComponent = additionalEditComponent;
//     this._oldObject = this.currentChildComponent._oldEngagementMeeting;
//     this._newObject = JSON.stringify(this.currentChildComponent.engagementMeetingDetails);
//   }
// }

// setTeamComponent() {
//   if (this.actionType === "Create") {
//     this._message = 'Associate will not be added, Do you want to Continue?';
//     this._detail = 'Associate not added';
//   } else {
//     this._message = 'Changes will not be saved, Do you want to continue?';
//     this._detail = 'Changes not saved';
//   }

//   if (this.childTeamCreateEditComponent) {
//     this.currentChildComponent = this.childTeamCreateEditComponent;
//     this._oldObject = this.childTeamCreateEditComponent.oldTeamAssociateModel;
//     this._newObject = JSON.stringify(this.childTeamCreateEditComponent.teamAssociateData);
//   } else {
//     this._oldObject = "";
//     this._newObject = "";
//   }
// }


clearFormCompareObjects()
{
    this._oldObject=""; 
     this._newObject="";
     this.isformObjectChange=false;
}
onSubTabSelect(event?:any)
  {
   
    this.subTabindex=event.index;
   this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);
    if (this.isformObjectChange)
    {
        this.confirmationService.confirm({
          message: this._message,
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.previousSubTabIndex=this.subTabindex;
            this.clearFormCompareObjects();
            this.SubTabSelect(this.subTabindex)
            this.messageService.add({
              key: 'tc',
              severity: 'info',
              summary: this._detail,
              detail: '',
          });
          },
          reject: () => {
            this.activeSubTabIndex = this.previousSubTabIndex;
            this.subTabView.activeIndex = this.previousSubTabIndex;
            setTimeout(() => {
              this.activeSubTabIndex = this.previousSubTabIndex;
              this.subTabView.activeIndex = this.previousSubTabIndex;
           },10);
           
          },
        });
    }
    else{
      this.previousSubTabIndex=this.subTabindex;
      this.clearFormCompareObjects();
      this.SubTabSelect(this.subTabindex)
    }



  }

  SubTabSelect(subTabIndex:number)
  {

    this.subTabView.activeIndex = subTabIndex;
    switch (subTabIndex) {
      case 0:
        this._currentKpiChildComponent=1;
       this.activeSubTabIndex = subTabIndex;
        break;
        case 1:
          this._currentMeetingChildComponent=1;
          this.activeSubTabIndex = subTabIndex;
        break;
        case 2:
          this._currentAuditChildComponent=1;
          this.activeSubTabIndex = subTabIndex;
          if(this.childAuditCreateComponent!=undefined)
            this.childAuditCreateComponent.submitted = false;
        break;
  }
  }

handleSaveAddButtonClick(saveButtonType:number)//common save method for all child components
{
  this.clearFormCompareObjects();
  switch (saveButtonType) {
    case 1:
      this._currentKpiChildComponent=4;
        break;
    case 2:
      this._currentMeetingChildComponent=4;
        break;
        case 4:
          //team tab
          this._currentTeamChildComponent=3;//View associate
          this.currentChildComponent=this.childTeamCreateEditComponent;
           break;
}
}
handleAuditEditButtonClick(data: any) {
 
  this.auditId=data.auditId;
  this._currentAuditChildComponent=3;
  this.currentChildComponent=this.childAuditEditComponent;
 }
 
 handleEditButtonClick(data: any,editButtonType:number) {
  
  switch (editButtonType) {
    case 1:
      this.kpiId=data.kpiId;
      this._currentKpiChildComponent=3;
      this.currentChildComponent=this.childKpiEditComponent;
      break;
    case 2:
     
      this.meetingId=data.meetingId;
      if(data.isRecurring)
      {
        this._currentMeetingChildComponent=5;
        this.currentChildComponent=this.childMeetingRecYesEditComponent;
      }
      else{
        this._currentMeetingChildComponent=3;
        this.currentChildComponent=this.childMeetingEditComponent;
      }
        break;
        case 4:
          //team tab
         this._currentTeamChildComponent=2;//Add associate
         this.actionType='Edit';
         this.associateId=data.associateId;
         this.currentChildComponent=this.childTeamCreateEditComponent;
           break;
}
  
 }

 handleAuditViewButtonClick(data: any) {
   this.auditId=data.auditId;
  this._currentAuditChildComponent=4;
  this.currentChildComponent=undefined;
 }

 handleViewButtonClick(data: any,viewButtonType:number) {


  switch (viewButtonType) {
    case 1:
      this.kpiId=data.kpiId;
       this._currentKpiChildComponent=4;
       this.currentChildComponent=undefined;
        break;
    case 2:
      this.meetingId=data.meetingId;
       this._currentMeetingChildComponent=4;
       this.currentChildComponent=undefined;
        break;
        case 4:
          this.associateId=data.associateId;
          this._currentTeamChildComponent=3;
          break;
  
}
}

 handleSubTabChange(e:any) {
  if ( e.index == 2){
    this._currentAuditChildComponent=1;
  }
}
getFormattedValue(value: number, currencyName: string): string {
  return this.currencyService.getFormattedValue(value, currencyName);
}
ParentTabSelect(ParentTabIndex:number)
{
  switch (ParentTabIndex) {
    case 0:
      this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.EVENG,this._engagement.engagementName,"Profile");
      break;
      case 1:
        this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.EVENG,this._engagement.engagementName,"Functions");
        this.BindEngagementContractsGroups();
      break;
      case 2:
        this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.EVENG,this._engagement.engagementName,"Contracts");
      break;
      case 3:
        this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.EVENG,this._engagement.engagementName,"Governance");
      break;
      case 4:
        this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.EVENG,this._engagement.engagementName,"Finance");
      this.showSummaryView=true;
      break;
      case 5:
        this._currentTeamChildComponent=1;
        this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.EVENG,this._engagement.engagementName,"Team");
        break;
}
}
tabindex:number = 0;
_details:string="";
onTabSelect(event?:any)
{
  this.tabindex=event.index;
  this.showSummaryView=false;
  if(this.tabindex==0)
  {
    this.engagementService.GetEngagementById( this.engagementId);
  }

  if(this.tabindex==2){
    this.isContractTab=true;
    this.executeCanExist=false;
  }
  else{
    this.isContractTab=false;
  }


  this.CommonTabClick(this.previousSubTabIndex,this.Previoustabindex);
  if (this.isformObjectChange)
  {
      this.confirmationService.confirm({
        message: this._message,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.Previoustabindex=this.tabindex;
          this.isformObjectChange=false;
          this._oldObject="";
          this._newObject="";
          this.ParentTabSelect(this.tabindex)
          this.resetallsubtabs();
          this.messageService.add({
            key: 'tc',
            severity: 'info',
            summary: this._details,
            detail:'',
        });
        },
        reject: () => {
          setTimeout(() => {
            this.tabindex=this.Previoustabindex;
            this.activeTabIndex = this.Previoustabindex;
            this.tabView.activeIndex = this.Previoustabindex;
         },250);
         
        },
      });
  }
  else{
    this.Previoustabindex=this.tabindex;
    this.isformObjectChange=false;
    this._oldObject="";
    this._newObject="";
    this.resetallsubtabs();
    this.ParentTabSelect(this.tabindex)
  }

 
}
resetallsubtabs()
{
  this._currentKpiChildComponent=1;
  this._currentMeetingChildComponent=1;
  this._currentTeamChildComponent=1;
  this.subTabindex = 0;
}
BindEngagementContractsGroups(){
  this.setDefaultCurrency();
  this.loaderService.showLoader();
  this.engagementService.GetAllEngagementContractGroupsById(this.engagementId).subscribe(res=>{
   this.engagementContractsGroups=res.data;
   this.engagementContractsGroupsCopy=res.data;
   this.GetContractList();

  this.engagementContractsGroups.forEach((group: { totalActiveContracts: number; totalContractedValue: number; totalOnboardAssociates: number; }) => {
    if (!group.totalActiveContracts) {
      group.totalActiveContracts = 0;
    }
    if (!group.totalContractedValue) {
      group.totalContractedValue = 0;
    }
    if (!group.totalOnboardAssociates) {
      group.totalOnboardAssociates = 0;
    }
  });
   this.loaderService.hideLoader();
  })
}


// -----------------Namita dashboard start------------------
displayContractGroupdashboard=false;
  reportingServiceURL="";
  selectedContractGroupData:any;
  selectedContractGroupId:any;
  showDashboard()
  {
    this.loaderService.showLoader();
   
    this.GetReportingServicesEmbedURL();
  }


  contractGroupdashboardId:any;
  public async GetReportingServicesEmbedURL() {
    this.contractGroupdashboardId=environment?._contractGroupDashboardId;
    let model = {
      "dashboardID": this.contractGroupdashboardId,//"d4fafce8-b6ad-4bb7-a62d-c211eecb4d58",
      "token":  this.authService.getCookieValue('LOGIN_TOKEN'),
      "privilege": [
        {
          "MenuName": "string",
          "isAllDataAvailable": true,
          "DataValues": []
        }
      ]
    };
  
    // Create an observable for the firstEmdbedURL
  this._dashboaredService.getEmbedUrlData(model).subscribe(
    async (res: any) => {

        this.reportingServiceURL=res.data;

        await this.Dashboard();
  });
  
    // Combine the observables using forkJoin
   
  }

  public async Dashboard() {
    let containerDiv = document.getElementById("dashboardContainer") || '';
    if (containerDiv && containerDiv instanceof HTMLElement) {
      containerDiv.innerHTML = ''; // Clear the container
  }
    const embeddingContext: EmbeddingContext = await createEmbeddingContext();
    
    const frameOptions = {
      url: this.reportingServiceURL + '&#p.ContractGroupId=' + this.selectedContractGroupId,
      container: containerDiv,
      height: "400px",
      width: "700px",
      resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent:any, metadata:any) => {
        switch (changeEvent.eventName) {
          
            case 'FRAME_LOADED': {
            
                this.loaderService.hideLoader();
              
                break;
            }
        }
    },
  }
    
   embeddingContext.embedDashboard(frameOptions);

  }

  BackToFunctions(){
    this.displayContractGroupdashboard=false;
  }
  TileClickEvent(contractGroupData:any)
  {
   
    var selectedData=this.engagementContractsGroupsCopy.filter((aa:any)=>aa.id===contractGroupData.id)
    this.selectedContractGroupData=selectedData[0];
    this.selectedContractGroupId=this.selectedContractGroupData.id;
    this.displayContractGroupdashboard=true;
  }

// -----------------Namita dashboard end------------------
selectedCurrency:any;
currenciesData : any[] = [];
conversionRate=new ConversionRate();
//---------------------Nagaraju Curruncy Changes Code--------------------------
OnCurrencyChange(event: any) {
  this.selectedCurrency = event.value;
  this.ConvertContactValuesByCurrency(event.value);
}
engagementContractsGroupsCopy: any;
baseCurrencyCode:any;
ConvertContactValuesByCurrency(currency: any) {
    const originalData = [...this.engagementContractsGroupsCopy];
    this.engagementContractsGroups = originalData.map(con => ({ ...con }));

  const baseCurrency = this._engagement.defaultCurrency;
  const baseCurrencyObj = this.currencyCodeList.find(currency => currency.id === baseCurrency);
  if (baseCurrencyObj) {
    this.baseCurrencyCode = baseCurrencyObj.code;
  }

    const conversionObservables = this.engagementContractsGroups.map((con: any) => {
        return this.currencyService.getExchangeRate(this.conversionRate, this.baseCurrencyCode, currency).pipe(
            tap((amount: number) => {
                con.totalContractedValue *= amount;
            })
        );
    });

    forkJoin(conversionObservables).subscribe(() => {
         this.updateSummaryValues();
    });
}
totalContractedValue:any;
private updateSummaryValues() {
   this.totalContractedValue = this.engagementContractsGroups.reduce((t: any, valu: { totalContractedValue: any; }) => t + valu.totalContractedValue, 0);
}

baseCurrency:string="";
setDefaultCurrency() {
  const defaultCurrency = this._engagement.defaultCurrency;
  
  const selectedCurrencyObj = this.currencyCodeList.find(currency => currency.id === defaultCurrency);
  if (selectedCurrencyObj) {
    this.selectedCurrency = selectedCurrencyObj.code;
    this.baseCurrency=this.selectedCurrency;
  }
}


GetConversionRates(){
  this.currencyService.GetConversionRates.subscribe(
    (result:any)=>{
      this.conversionRate=result.data.conversionRates;
    }
  );
}

//Finance Dashboard Code

fiscalYearList:any[]=[];

IsDashboardTillDate=true;
    selectedFiscalYear:any;
    selectedDashboardToView="";
    DashboardToViewDataSource: any[] = [
      { label: 'Till Date Values', value: 'tillDate' },
      { label: 'Fiscal Year Values', value: 'fiscalYear' }
    ];

    
    onDashboardToViewChange(data:any){
      if(data.value=='tillDate'){
        this.IsDashboardTillDate=true;
        this.fiscalYearList=[];
        this.fiscalYearList.push({ label: 'Not Applicable', value: 'NotApplicable' });
        this.selectedFiscalYear=this.fiscalYearList[0].value;
        this.isApplyDisable=false;
      }
      else{
        this.IsDashboardTillDate=false;
        this.loaderService.showLoader();
        this.getContractForFinanceDashboard();
        this.isApplyDisable=true;
      }
    }


    isApplyDisable=true;
onFiscalYearChange(data:any){
  if(data.value != null){
  this.isApplyDisable=false;
  }
  else{
    this.isApplyDisable=true;
  }
}

    engagementContractData:any;
    getContractForFinanceDashboard(){
      this._contractService.GetAllContracts(this.engagementId,[0,0]).subscribe(
        res => {
          this.engagementContractData = res.data;
          this.GetFunctionContracts();
        });
    }

    onDashboardApplyClick(){
      this.selectedContractGroupId=this.selectedContractGroupData.id;
        if(this.IsDashboardTillDate){
          this.GetReportingServicesEmbedURLForFinanceTillDate();
          
        }
        else{
          this.GetReportingServicesEmbedURLForFinanceFiscalYear();
        }
    }


financeTillDateDashboardId:any;
reportingURLforFinanceTillDate:any;
    public async GetReportingServicesEmbedURLForFinanceTillDate() {
    
      this.financeTillDateDashboardId=environment?._financeTillDateDashboardId;
      let model = {
        "dashboardID": this.financeTillDateDashboardId,//"d4fafce8-b6ad-4bb7-a62d-c211eecb4d58",
        "token": this.authService.getCookieValue('LOGIN_TOKEN'),
        "privilege": [
          {
            "MenuName": "string",
            "isAllDataAvailable": true,
            "DataValues": []
          }
        ]
      };
    
      // Create an observable for the firstEmdbedURL
    this._dashboaredService.getEmbedUrlData(model).subscribe(
      async (res: any) => {
  
          this.reportingURLforFinanceTillDate=res.data;
  
          await this.FinanceTillDashboard();
    });
    
      // Combine the observables using forkJoin
     
    }
  
    public async FinanceTillDashboard() {
      let containerDiv = document.getElementById("financeDashboardContainer") || '';
      const embeddingContext: EmbeddingContext = await createEmbeddingContext();
     
     if (containerDiv && containerDiv instanceof HTMLElement) {
      containerDiv.innerHTML = ''; // Clear the container
  }
      const frameOptions = {
       
        url: this.reportingURLforFinanceTillDate + '&#p.ContractGroupId=' + this.selectedContractGroupId + '&p.Currency=' + this.baseCurrency,//628,668,958 + this.selectedContractGroupId,
        container: containerDiv,
        height: "800px",
        // width: "700px",
        resizeHeightOnSizeChangedEvent: true,
        onChange: (changeEvent:any, metadata:any) => {
          switch (changeEvent.eventName) {
            
              case 'FRAME_LOADED': {
              
                   
                
                  break;
              }
          }
      },
    }
      
     embeddingContext.embedDashboard(frameOptions);
  
    }

selectedGroup:any;

GetContractList() {
  this._contractService.GetAllContracts(this.engagementId,[0,0]).subscribe(
      res => {
          if (res && res.status && res.data && res.data.length > 0) {
           
              const data = res.data || [];
              this.selectedGroup = data.filter((contract: { ownerFunctionId: any; }) => {
                  return this.engagementContractsGroups.some((group: { id: any; }) => group.id === contract.ownerFunctionId);
              });
              
              this.intialConversion();
            
          }
      }
  );
}
isIntialConvert:boolean=false;
intialCurrencyCode:any;
intialConversion() {
this.isIntialConvert=true;
const intialCurrency = this.selectedGroup[0].currencyId;
const intialCurrencyObj = this.currenciesData.find(currency => currency.id === intialCurrency);
if (intialCurrencyObj) {
  this.intialCurrencyCode = intialCurrencyObj.code;
}

const conversionObservables = this.engagementContractsGroups.map((con: any) => {
  return this.currencyService.getExchangeRate(this.conversionRate, this.intialCurrencyCode, this.selectedCurrency).pipe(
      tap((amount: number) => {
         con.totalContractedValue *= amount;
      })
  );
});

forkJoin(conversionObservables).subscribe(() => {
  
});
}
excludedSponsorContactIds: number[] = [];
excludedSupplierContactIds: number[] = [];

functionContracts:any;
GetFunctionContracts(){
  this.loaderService.showLoader();
  
  this.functionContracts=this.engagementContractData.filter((aa:any)=>aa.ownerFunctionId===this.selectedContractGroupId);
this.GetEngagementFiscalYearDetails();
}

engagementFiscalYearData:any;
GetEngagementFiscalYearDetails(){
  this._financeInvoiceService.GetAllFiscalYearDetailsByEngagementId(this.engagementId).subscribe(
    res => {
      if (res.data) {
        this.engagementFiscalYearData=res.data;
        this.GetFiscalYearList();
      }
});
}


GetFiscalYearList(){
  const filteredData = this.engagementFiscalYearData.filter((efy:any) =>
    this.functionContracts.some((fc:any) => fc.ContractDetailId === efy.ContractDetailId)
  );

  
  this.fiscalYearList=[];
  const distinctFiscalYears = Array.from(new Set(filteredData.map((item:any) => item.fiscalYear)));
  
  // Sort the distinct fiscal years in ascending order
  distinctFiscalYears.sort((a:any, b:any) => {
    const [aStartYear, aEndYear] = a.split(' - ').map((part:any) => parseInt(part.split(' ')[1]));
    const [bStartYear, bEndYear] = b.split(' - ').map((part:any) => parseInt(part.split(' ')[1]));

    return aStartYear - bStartYear || aEndYear - bEndYear;
  });
  
  this.fiscalYearList = distinctFiscalYears.map(fiscalYear => ({
    label: fiscalYear,
    value: fiscalYear
  }));
  setTimeout(() => {
    this.loaderService.hideLoader();
  }, 2000);
}

//FiscalYearDashboardData

financeFiscalYearDashboardId:any;
reportingURLforFinanceFiscalYear:any;
    public async GetReportingServicesEmbedURLForFinanceFiscalYear() {
      this.financeFiscalYearDashboardId=environment?._financeFiscalYearDashboardId;
      let model = {
        "dashboardID": this.financeFiscalYearDashboardId,//"d4fafce8-b6ad-4bb7-a62d-c211eecb4d58",
        "token": this.authService.getCookieValue('LOGIN_TOKEN'),
        "privilege": [
          {
            "MenuName": "string",
            "isAllDataAvailable": true,
            "DataValues": []
          }
        ]
      };
    
      // Create an observable for the firstEmdbedURL
    this._dashboaredService.getEmbedUrlData(model).subscribe(
      async (res: any) => {
  
          this.reportingURLforFinanceFiscalYear=res.data;
  
          await this.FinanceFiscalYearDashboard();
    });
    
      // Combine the observables using forkJoin
     
    }
  
   
    public async FinanceFiscalYearDashboard() {
      let containerDiv = document.getElementById("financeDashboardContainer") || '';
      if (containerDiv && containerDiv instanceof HTMLElement) {
        containerDiv.innerHTML = ''; // Clear the container
    }
      const embeddingContext: EmbeddingContext = await createEmbeddingContext();
     
     
     let encodedFiscalYear = encodeURIComponent(this.selectedFiscalYear);
      const frameOptions = {
       
        url: this.reportingURLforFinanceFiscalYear + '&#p.ContractGroupId=' + this.selectedContractGroupId + '&p.FiscalYear=' + encodedFiscalYear + '&p.Currency=' + this.baseCurrency,
        container: containerDiv,
        height: "800px",
        // width: "700px",
        resizeHeightOnSizeChangedEvent: true,
        onChange: (changeEvent:any, metadata:any) => {
          switch (changeEvent.eventName) {
              case 'FRAME_LOADED': {
                  break;
              }
          }
      },
    }
      
     embeddingContext.embedDashboard(frameOptions);
  
    }

    onMSAOwnerChange(event: any) {
      const selectedMSAOwnerId = event.value;
      this.filterContacts(selectedMSAOwnerId, 'sponsor');
    }
    
    onStakeholderChange(event: any) {
      const selectedStakeholderId = event.value;
       this.filterContacts(selectedStakeholderId,'supplier');
    }

   
    filterContacts(selectedContactId: any, type: 'sponsor' | 'supplier') {
      if (type === 'sponsor') {
        const sponsorContact = this.sponsorContactsDataSource.find(contact => contact.id ===this.oldSponsorId);
        if(!sponsorContact){
          const newsponsorContact = this.totalSponsorContacts.find((contact: { id: number; }) => contact.id ===this.oldSponsorId);
          if(newsponsorContact){
            this.sponsorContactsDataSource.push(newsponsorContact);
          }
        }
        }

       if (type === 'supplier') {
        const supplierContact = this.supplierContactsDataSource.find(contact => contact.id ===this.oldSupplierId);
        if(!supplierContact){
          const newsupplierContact = this.totalSupplierContacts.find((contact: { id: number; }) => contact.id ===this.oldSupplierId);
          if(newsupplierContact){
            this.supplierContactsDataSource.push(newsupplierContact);
          }
        }
      }
    }
  
    sponsorExcludedData:any;
    supplierExcludedData:any;
    updateMsaContactLists(contactType:string) {
      if(contactType=='sponsor'){
      // Update MSA sponsor contact list
      this.sponsorContactsDataSource.filter(
        sponsor => sponsor?.id && !this.stakeholderSponsorContacts.some(contact => contact?.id == sponsor.id));

      const sponsorExcludedIds = this.stakeholderSponsorcontactData.map((contact: any) => contact.id);
    const unselectedIds = this.totalSponsorContacts
    .filter((contact: any) => !sponsorExcludedIds.includes(contact.id))
    .map((contact: any) => contact.id);
      this.sponsorExcludedData = unselectedIds; 

this.allMsaSponsorContactsDataSource = this.totalSponsorContacts.filter((contact: any) => 
  unselectedIds.includes(contact.id)
);

}
      
if(contactType=='supplier'){
  this.supplierContactsDataSource.filter(
    supplier => supplier?.id && !this.stakeholderSupplierContacts.some(contact => contact?.id == supplier.id));

  const supplierExcludedIds = this.stakeholderSuppliercontactData.map((contact: any) => contact.id);
const supplierIds = this.totalSupplierContacts
.filter((contact: any) => !supplierExcludedIds.includes(contact.id))
.map((contact: any) => contact.id);
  this.supplierExcludedData = supplierIds; 

this.allMsaSupplierContactsDataSource = this.totalSupplierContacts.filter((contact: any) => 
  supplierIds.includes(contact.id)
);

}
    }  
    //get all suppliers data
GetAllEngagements() {
  this.engagementService.GetEngagementList().subscribe(
    (    res: { data: any[]; })=>{
      this.allEngagementdetails=res.data;
    } 
 );
};
checkEngagementNameExist()
{
 
this.uniqueEngagementMessage=false;
const contactIndex = this.allEngagementdetails.findIndex(item => item.engagementName.toLowerCase() === (this._engagement.engagementName?.toLocaleLowerCase()));
 if(contactIndex !== -1 && parseInt(this.engagementId)!==this.allEngagementdetails[contactIndex].id)
 {
    this.uniqueEngagementMessage=true;
 }
  else{
    this.uniqueEngagementMessage=false;
  }
}
excludeContact(){
const sponsorContactIdsToRemove = this._supplierMSADetails.map(item => item.sponsorContactId);
this.sponsorContactsDataSource = this.sponsorContactsDataSource.filter(contact => 
  !sponsorContactIdsToRemove.includes(contact.id) // assuming contact has an 'id' property
);

const supplierContactIdsToRemove = this._supplierMSADetails.map(item => item.supplierContactId);
this.supplierContactsDataSource = this.supplierContactsDataSource.filter(contact => 
  !supplierContactIdsToRemove.includes(contact.id) // assuming contact has an 'id' property
);


}
}

import { Component, OnInit} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { SponserService } from 'src/app/MasterData/Services/sponser.service';
import { ContracAddendumDetails, ContractChangeOrder, ContractDetails, ContractBillingTypes, } from '../../Models/contract-details';
import { SponsorContact, SupplierContact, SupplierProfile } from 'src/app/MasterData/Services/supplier-profile';
import { ContractService } from '../../Services/contract.service';
import { EngagementDropdownBinding } from '../../Models/supplier-binding-dropdown';
import { } from 'date-fns';
import { Dateformat,breadcrumnsCodes,FileExtension, CurrencyType, AuditIdentifier } from 'src/app/shared/constants/global.constants';
import { LoaderService } from 'src/app/Loader/loader.service';
import {DatePipe, formatDate } from '@angular/common';
import { IDeactivateGuard } from 'src/app/shared/Guards/can-deactivate-guard.service';
import { Observable } from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/Breadcrumb/breadcrumb.service';


import { PrivilegeService } from 'src/app/Reusable/privilege.service';

import { AuditLogModel } from 'src/app/shared/ModelComparison/audit.log.response.model';
import { AuditLogService } from 'src/app/shared/ModelComparison/audit.log.service';
import { CodeListValues } from 'src/app/MasterData/Models/country-data';
import { ITbl_SponserContacts } from 'src/app/MasterData/Models/sponser-data';
import { } from 'date-fns/locale';
import { MasterSupplierService } from 'src/app/MasterData/supplier/Services/master.supplier.service';
import { EngagementService } from 'src/app/Engagement/Services/engagement.service';
import { ContractGroupModel, EngagementSponsorContact, EngagementSupplierContact } from 'src/app/Engagement/Models/Engagement';
import { FileUploadService } from 'src/app/Engagement/Services/fileupload.service';
import { TabAndStateService } from 'src/app/shared/TabAndStateService/TabAndState.Service';

@Component({
  selector: 'app-createcontract',
  templateUrl: './createcontract.component.html',
  styleUrls: ['./createcontract.component.css']
})

export class CreatecontractComponent implements OnInit,IDeactivateGuard {
  displaySponsorStakeholder: boolean = true;
  value: string | undefined;
  visible: boolean = false;
  myContractDetailsForm: FormGroup;
  contractsChangeOrderForm: FormGroup;
  contractsAddendumDetailsForm:FormGroup;
  dialogFormGroup: FormGroup;
  contactFormGroup:FormGroup;
  contractDetails=new ContractDetails();
  supplierdata : any[] = [];
  supplierMSAdata : any[] = [];
  BillingTypeData : any[] = [];
 statusData : any[] = [];
 currenciesData : any[] = [];
 supplierContactData : any[] = [];
 contractGroupData :any[]=[];
 _contractChangeOrder :any[]=[];
 _contractAddendumDetail:any[]=[];
 changeOrderStatusDataSource : any[]=[]; 
 addendumStatusDataSource : any[]=[]; 
 addendumDetailStatusDataSource:any[]=[];
 _changeOrders: ContractChangeOrder[]=[];
 _changeOrder=new ContractChangeOrder();
 _addendumDetails:ContracAddendumDetails[]=[];
 _addendumDetail=new ContracAddendumDetails();
 sponsorContactsDataSource : SponsorContact[]=[];
 _contractBillingTypes = new ContractBillingTypes();
 _contractBillingTypesDetails: ContractBillingTypes[]=[];
 _contractBillingTypestosave = new ContractBillingTypes();
_contractAddendum:any;
 submitted = false;
 uniqueMessage=false;
 changeOrderSaved = false;
 addendumDetailsSaved = false;
 contractValue:any;
finalValue:any;
changeOrderValue:number=0;
IsDisabled:boolean=true;
executeCanExist: boolean = false;
engagementDropdownBindingDataList:EngagementDropdownBinding[]=[];
engagementDropdownBindingData=new EngagementDropdownBinding();

editedEngagement:any;
editedEngagementName:any;
isEngagementDisabled=false;
selectedItem: any;
oldSelectedItem: any;
_dateformart :any;
startDate:any;
endDate:any;
uploadedSOWFileData: any;
uploadedPOFileData: any;
maxFileSize:any
uploadedChangeOrderFileData: any;
uploadedAddendumFileData: any;
changeOrderDataSource : any[]=[]; 
uploadedMultipleChangeOrderFileData : any[]=[];
uploadedMultipleAddendumFileData : any[]=[];
timeStamp:any;
formData =  new  FormData();
addendumDetailsDataSource : any[]=[]; 
currentDialog: string="";
  isLoading=false;
  displayChangeOrderDialog: boolean = false;
  displayAdendumDialog: boolean = false;
  sDate:any;
eDate:any;
_enumBreadcrumb :any;
addAddendumShowAttachmentButton:boolean=false;
addAddendumShowAttachedFile:boolean=true;
showAttachmentButton:boolean=false;
  showAttachedFile:boolean=true;
  multiFileData : any[]=[]; 
  contractCurrency:any;
oldChangeOrderValue:any;
_oldContractDetails:any;
_oldContractAddendumDetails:any;
_oldContractChangeOrderDetails:any;
ChangeOrderTitle='Add Change Order';
AddendumTitle='Add Addendum';
currency:any;
supplierIds:any;
engagementIds:any;
countryCodes: CodeListValues[] | undefined;
sponserContact:ITbl_SponserContacts={};
displayDialog:boolean=false;
contactSubmitted=false;
contractGroupSubmitted=false;
selectedSupplierContactId: any;
selectedSponsorContactIds: any[] = [];
selectedSponsorContactId:any
selectedSponsorContactDetails:any
stakeholderSponsorContacts: any[] = [];
stakeholderSupplierContacts:any[]=[];
contractGroup:any;
selectedContractGroup: string = '';
notificationOptionsDataSource: CodeListValues[] | undefined;
filteredSupplierContacts: any[] = [];
filteredStakholderSponsorContacts:any;
filteredSponsorContacts: any[] = [];
  constructor(private _masterDataService:SponserService,
    private privilegeService: PrivilegeService,
    private _contractService : ContractService,
    private router:Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    private loaderService: LoaderService,
    private masterDataService:SponserService,
    private breadcrumbService: BreadcrumbService,
    private _fileService:FileUploadService,
    private datepipe: DatePipe,
    private auditLogService:AuditLogService,
    private _service:SponserService,
    private _mastersupplierService: MasterSupplierService,
    private engagementService: EngagementService,
    private tabAndStateService: TabAndStateService
    )
    {
      this.myContractDetailsForm = this.fb.group({
        title: ['', [Validators.required, Validators.maxLength(100),this.validateAlphaCharacters]],
        contractId: ['', [Validators.required,Validators.maxLength(15)]],
        startDate:['',[Validators.required]],
        endDate:['',[Validators.required]],
        engagementId:['',[Validators.required]],
        supplierMasterServiceAgreementId:[, [Validators.required]],
        billingTypeId:[null,[Validators.required, this.validateMaxSelections.bind(this)]],
        SponserContactId:['',[Validators.required]],
        statusMappingId:['',[Validators.required]],
        currencyId:['',[Validators.required]],
        ownerSupplierContactId:['',[Validators.required]],   
        // ownerFunctionId:['',[Validators.required]],
        ownerFunctionId:[''],
        value:['',[Validators.required, Validators.maxLength(15),this.validateNumericField]],
        // totalValue:['',[Validators.required,this.validateNumericField]],
        totalValue:['',[Validators.required,this.validateNumericFieldwithNegative]],
        valuePerYear:['',[Validators.required]],
        description:['',[Validators.maxLength(300)]],
       // notesandComments:['',[Validators.maxLength(300)]],
        term:[''],
        //sowfileSource:['', Validators.required],sowFileName: '',
        sowfileSource:[''],sowFileName: '',
        ponumber: ['', [Validators.required, Validators.maxLength(15),this.noOnlySpacesValidator,Validators.pattern(/^\S*$/)]],
        povalue:['',[Validators.required, Validators.maxLength(15),this.validateNumericField]],
        postartDate:['',[Validators.required]],
        poendDate:['',[Validators.required]],
        //pofileSource:['', Validators.required],poFileName: '',
        pofileSource:[''],poFileName: '',
        poremarks:['',[Validators.maxLength(300)]],
      });
  this.contractsChangeOrderForm=this.fb.group({
    _changeOrderTitle: ['', [Validators.required, Validators.maxLength(100),Validators.pattern(/^[\S\s]*$/),this.noOnlySpacesValidator]],	///^[a-zA-Z\s]{1,50}$/
    _changeOrderStartDate:['',[Validators.required]],
    _changeOrderEndDate:['',[Validators.required]],
    //_changeOrderValue:['',[Validators.required, Validators.maxLength(15),this.validateNumericField]],
    _changeOrderValue:['',[Validators.required, Validators.maxLength(15),this.validateNumericFieldwithNegative]],
    _changeOrderCurrency:[''],
    //_changeOrderfileSource :['',[Validators.required]],_changeOrderFileName:'',
    _changeOrderfileSource :[''],_changeOrderFileName:'',
    _changeOrderStatus:['',[Validators.required]],
    _changeOrderDescription:['',[Validators.maxLength(300)]],
  });

  this.contractsAddendumDetailsForm=this.fb.group({
    _addendumDetailTitle: ['', [Validators.required, Validators.maxLength(100),Validators.pattern(/^[\S\s]*$/),  this.noOnlySpacesValidator]],	
    _addendumDetailStartDate:['',[Validators.required]],
    _addendumDetailEndDate:['',[Validators.required]],
    //_addendumDetailfileSource :['',[Validators.required]],_addendumDetailFileName:'',
    _addendumDetailfileSource :[''],_addendumDetailFileName:'',
    _addendumDetailStatus:['',[Validators.required]],
    _addendumDetailDescription:['',[Validators.maxLength(300)]],
  });
  this.dialogFormGroup = this.fb.group({
    contactName: ['', [Validators.required,Validators.maxLength(30),Validators.pattern(/^[a-zA-Z\s]{1,30}$/),this.notOnlySpacesValidator]],
    contactDesignation:['', [Validators.maxLength(100),Validators.minLength(3),this.notOnlySpacesValidator]],
    countryCode: [''],
    phoneNumber:['',[Validators.minLength(7),Validators.maxLength(15)]],
    email:['',[Validators.required,Validators.maxLength(100)]]
  });
  this.contactFormGroup=this.fb.group({
    contractGroupName:['', [Validators.required,Validators.maxLength(50),Validators.minLength(3),this.validateOnlyNumberandSpecialCharecters,this.noOnlySpacesValidator]]
  });
 }
 
    validateAlphaCharacters(control: AbstractControl): ValidationErrors | null {
      const value = control.value;
  
      
      const pattern = /^[\S\s]*$/;
  
      if (value && !pattern.test(value)) {
        return { invalidAlphaCharacters: true };
      }
  
      return null;
    }   
    validateOnlyNumberandSpecialCharecters(control: AbstractControl): ValidationErrors | null{
      const alphaRegex = /[a-zA-Z]/; // Ensure at least one alphabetic character
      if (control.value && !alphaRegex.test(control.value)) {
        return { invalidNumberSpecialCharecters: true };
      }
      return null;
    }
  getDefaultMinDate(): Date {
    // Set your desired default minimum date
    return  new Date() // For example, you can set it to the current date
  }
  
    validateNumericField(control: any) {
      const numericRegex = /^\d+(\.\d{1,2})?$/;
      if (control.value && !numericRegex.test(control.value)) {
        return { invalidNumber: true };
      }
      return null;
    }
    validateNumericFieldwithNegative(control: any) {
      const numericRegex = /^-?\d+(\.\d{1,2})?$/; //  allow negative numbers
      if (control.value && !numericRegex.test(control.value)) {
        return { invalidNumber: true };
      }
      return null;
    }
    trimInput() {
      const control = this.contactFormGroup.get('contractGroupName');
      if (control && control.value) {
        control.setValue(control.value.trim());
      }
    }
    formattedDate:any
    auditIdentifierDetails:any;
ngOnInit() {
  let userData=this.privilegeService.getUserData();
  this.supplierIds=userData.suppliers;
  this.engagementIds=userData.engagements;
  this._enumBreadcrumb=breadcrumnsCodes;
  this.generateBreadcrumb();
  this._dateformart=Dateformat;
  this._service.getCodeListData(0,'').subscribe(
    data=>{
      this.countryCodes=data.data;
      
    }
  );
  this.auditIdentifierDetails=AuditIdentifier; 
  this.activeRouter.params.subscribe(params => {
    this.editedEngagement = params['id']; //log the value of id
  });

 
this.GetBillingTypeCodeList();
this.GetAllNotificationOptions(20);
 this.GetContractStatus();
 this.GetCurrencyData();
 this.GetAllEngagementsData();
 
 this.oldContractData=JSON.stringify(this.contractDetails);
 this.oldChanrOrderData=JSON.stringify(this._changeOrders);
 this.oldAddendumData=JSON.stringify(this._contractAddendumDetail);

}
      //get SendNotification options data
GetAllNotificationOptions(type:number) {
  this.masterDataService.getCodeListData(type,'').subscribe(
    res=>{
      this.notificationOptionsDataSource=res.data;
    }
  );
};
    GetBillingTypeCodeList(){
      this._masterDataService.getCodeListData(2,'').subscribe(
        data=>{
          this.BillingTypeData=data.data;
          
        }
      );
    }

    GetContractStatus(){
      this._masterDataService.getCodeListData(3,'Contract').subscribe(
        data=>{
          this.statusData=data.data;
          
        }
      );
    }

    GetCurrencyData(){
      this._masterDataService.getCodeListData(4,'').subscribe(
        data=>{
          this.currenciesData=data.data;
          
        }
      );
    }

    GetContractGroupData(id:number){
      this.engagementService.GetContractByEngagementId(id).subscribe(
        res=>{
          this.contractGroupData=res.data;
         let otherDetails=this.contractGroupData.filter(aa=>aa.name=='Others');
         if(otherDetails != undefined && otherDetails != null){
          this.contractDetails.OwnerFunctionId=otherDetails[0].id;
         }
        });
    }

engagementData:any;
GetAllEngagementsData(){
  this.loaderService.showLoader();
  if(this.editedEngagement){
    this.GetEngagementData();
  }
  else{
  this.engagementService.GetEngagementsByGroupTypes(this.engagementIds).subscribe(
    data=>{
      this.engagementData=data.data;
      this.engagementData.forEach((item: { id: number | undefined; engagementName: string | undefined; engagementID: string; }) => {
        this.engagementDropdownBindingData=new EngagementDropdownBinding();
        this.engagementDropdownBindingData.Id=item.id;
        this.engagementDropdownBindingData.Name=item.engagementName;
        this.engagementDropdownBindingData.Label=item.engagementName + ' [' + item.engagementID + ']';
       this.engagementDropdownBindingDataList.push(this.engagementDropdownBindingData);
       
      });

       this.loaderService.hideLoader();
    }
  );
  }
}
GetEngagementData(){
  this.loaderService.showLoader();
  this.engagementService.GetEngagementById(this.editedEngagement).subscribe(
    data=>{
      this.supplierdata=data.data;
     this.engagementDropdownBindingData=new EngagementDropdownBinding();
     this.engagementDropdownBindingData.Id=data.data.id;
     this.engagementDropdownBindingData.Name=data.data.engagementName;
     this.engagementDropdownBindingData.Label=data.data.engagementName + ' [' + data.data.engagementId + ']';
    this.engagementDropdownBindingDataList.push(this.engagementDropdownBindingData);

      if(this.editedEngagement != null && this.editedEngagement != undefined){
        let selectedSupplierDataIndex= this.engagementDropdownBindingDataList.findIndex(x=>x.Id==this.editedEngagement);
         this.contractDetails.EngagementId=this.engagementDropdownBindingDataList[selectedSupplierDataIndex].Id;
        this.editedEngagementName=this.engagementDropdownBindingDataList[selectedSupplierDataIndex].Name;
        this.isEngagementDisabled=true;
        this.GetEngagementById(this.contractDetails.EngagementId);
        this.generateBreadcrumb();
       }
       this.loaderService.hideLoader();
    }
  );
}

supplierData:any;
sponsorId:any;
supplierId:any
engagementsData:any;
supplierContacts:any;
sponsorContacts:any;
GetEngagementById(id:any){
  this.engagementService.GetEngagementById(id).subscribe(
    res=>{
      this.engagementsData=res.data;
      this.supplierMSAdata=res.data.engagementMasterServiceAgreements;
      this.supplierContacts=res.data.supplierContacts;
      this.sponsorContacts=res.data.sponsorContacts;
      this.supplierId=res.data.supplierId;
      this.sponsorId=res.data.sponsorId;
      this.contractDetails.CurrencyId=res.data.defaultCurrency;     
      this.GetSuppliersData(res.data.supplierId);
      this.GetSponsorsData(res.data.sponsorId);
       this.GetContractGroupData(id);
    });
}
totalSupplierContacts:any; 
GetSuppliersData(Id:any){
  this._mastersupplierService.getSupplierById (Id).subscribe(
    res=>{
       this.supplierContactData=res.data.supplierContacts;
        this.supplierData=res.data;
        this.supplierContacts=res.data.supplierContacts;
        this.filteredStakholderSupplierContacts= this.supplierContactData;
        this.filteredSupplierContacts=this.supplierContactData;
        this.totalSupplierContacts=  res.data.supplierContacts;  
    })
}
totalSponsorContact:any;
GetSponsorsData(Id:any){
  this._masterDataService.getSponserData (Id).subscribe(
    res=>{
      this.sponsorInfo=res.data;
      this.sponsorContactsDataSource = res.data.sponserContacts;
      this.sponsorContacts= res.data.sponserContacts;
      this.filteredStakholderSponsorContacts = this.sponsorContactsDataSource;
      this.filteredSponsorContacts = this.sponsorContactsDataSource;
      this.totalSponsorContact=res.data.sponserContacts;
    })
}

sponsorInfo:any;
selectedEngagement:any;
dropdownValueChanged: boolean = false;
onEngagementDropdownChange(event: any) {
  this. supplierContactList=[];
  this.sponserContactList=[];
  this.selectedContractGroup='';
  this.selectedEngagement=event.value;
  this.dropdownValueChanged = true;
this.GetEngagementById(this.selectedEngagement)
}

isContractIdExist(event: any) {
  const contractId = event.target.value;
  const contract=contractId?.replace(/-/g, '~');
  this._contractService.CheckContractIdUniqueness(contract,0).subscribe(
    res=>{
      if(res.data == 1){
        this.uniqueMessage=true;
      }
      else{
        this.uniqueMessage=false;
      }
    }
  );
}


onValueChange(event: any) {
  
  const value = event;
  this.contractValue=value;
  this.CalculateTotalValue(this.contractValue,this._changeOrders);
  
  
}

CalculateTotalValue(contractValue: number, changeOrderDetails: any) {
  this.contractDetails.TotalValue = contractValue;
  if (changeOrderDetails != null && changeOrderDetails != undefined && changeOrderDetails.length>0){
      for (let i = 0; i < changeOrderDetails.length; i++) {
          this.contractDetails.TotalValue += changeOrderDetails[i].value;
      }
  }
}

onChangeOrderValueChange() {
  this.finalValue = '';

  const totalValue = this.contractDetails.TotalValue ? +this.contractDetails.TotalValue : 0;
  const oldValue = this.oldChangeOrderValue ? +this.oldChangeOrderValue : 0;
  const changeOrderValue = this.changeOrderValue ? +this.changeOrderValue : 0;

  this.contractDetails.TotalValue = totalValue - oldValue + changeOrderValue;
  const sum = this._changeOrders.reduce((acc, order) => acc + (+order.value || 0), 0);
  this.finalValue = sum.toString();
  this.oldChangeOrderValue = '';
}


differenceYears:any;
differenceMonths:any;
differenceDays:any;
calDateDifference(): string {
  this.startDate=this.contractDetails.StartDate;
  this.endDate=this.contractDetails.EndDate;
  const timeDifference = this.endDate.getTime() - this.startDate.getTime();

  this.differenceYears = Math.floor(timeDifference / (365 * 24 * 60 * 60 * 1000));
    const remainingMilliseconds = timeDifference - (this.differenceYears * 365 * 24 * 60 * 60 * 1000);

 this.differenceMonths = Math.floor(remainingMilliseconds / (30 * 24 * 60 * 60 * 1000));

    const remainingMillisecondsAfterMonths = remainingMilliseconds - (this.differenceMonths * 30 * 24 * 60 * 60 * 1000);

this.differenceDays = Math.floor(remainingMillisecondsAfterMonths / (24 * 60 * 60 * 1000));



    if(this.differenceYears == 0 && this.differenceMonths==0 && this.differenceDays>0){
      return `1 months`;
    }
    else if(this.differenceYears == 0 && this.differenceMonths>0){
      return `${this.differenceMonths} months `
    }
    else if(this.differenceYears > 0 && this.differenceMonths==0){
      return `${this.differenceYears} years`
    }
    else if(this.differenceYears == 0 && this.differenceMonths==0 && this.differenceDays==0){
      return `0`;
    }
    else{
    return `${this.differenceYears} years and ${this.differenceMonths} months`;
    }
  
}


//previousStartDateValue:any;
onStartDateChange(event:any){
  this.checkContractStartDate(event);
 if(!this.startDateMessageFlag){
  this.startDate=event;
 
  if(this.startDate != null && this.startDate != undefined && this.contractDetails.EndDate != null && this.contractDetails.EndDate != undefined)
  {
    
    if( new Date(this.startDate)>new Date(this.contractDetails.EndDate))
    {
      this.myContractDetailsForm.patchValue({
        endDate: null,
        term:null
      });
     
    }
    else{
    this.contractDetails.Term=this.calDateDifference();
    }
  }
}
  else{
    this.startDateMessageFlag=false;
    this.myContractDetailsForm.patchValue({
      startDate: this.startDate,
      
    });
    
  }
  }
  

  startDateMessageFlag=false;
  checkContractStartDate(startDate:any){
  // Check in the Change Order
    if(this._changeOrders != null && this._changeOrders != undefined)
    {
      this._changeOrders.forEach(item => {
        if(item.StartDate != null && item.StartDate != undefined){
        if(new Date(startDate) > new Date(item.StartDate)){
          this.startDateMessageFlag=true;
        }
        }
      });
    }
      // Check in the Addendum
   
      if(!this.startDateMessageFlag && this._addendumDetails != null && this._addendumDetails != undefined){
        this._addendumDetails.forEach(item => {
          if(item.StartDate != null && item.StartDate != undefined){
            if(new Date(startDate) > new Date(item.StartDate)){
            this.startDateMessageFlag=true;
          }
          }
        });
      }
  
      //Check in the PO

if(this.contractDetails.POStartDate != null && this.contractDetails.POStartDate != undefined){
if(new Date(startDate)>new Date(this.contractDetails.POStartDate)){
  this.startDateMessageFlag=true;
}
}
  
if(this.startDateMessageFlag){
  this.messageService.add({  key: 'tc',severity: 'error', summary: 'Contract Start Date cannot be after Purchase Order, Change Order or Addendum Start Dates. Please change the Dates on Purchase Orders, Change Orders and/or Addendums first.', detail: '' });
}
  
  }
  
  endDateMessageFlag=false;
  checkContractEndDate(endDate:any){
  // Check in the Change Order
    if(this._changeOrders != null && this._changeOrders != undefined)
    {
      this._changeOrders.forEach(item => {
        if(item.EndDate != null && item.EndDate != undefined){
        if(new Date(endDate) < new Date(item.EndDate)){
          this.startDateMessageFlag=true;
        }
        }
      });
    }     
if(this.startDateMessageFlag){
  this.messageService.add({  key: 'tc',severity: 'error', summary: 'Contract End Date cannot be before a CO End Date', detail: '' });
}
if(!this.startDateMessageFlag){
  this.endDate=endDate;
  }
  else{
    this.startDateMessageFlag=false;
    this.myContractDetailsForm.patchValue({
      endDate: this.endDate,
      
    });
}
}

  onPODateChange(event:any){
    this.contractDetails.POStartDate=event;
    if(this.contractDetails.POStartDate!=null && this.contractDetails.POStartDate!=undefined && this.contractDetails.POEndDate!=null && this.contractDetails.POEndDate!=undefined){
      if( this.contractDetails.POStartDate>this.contractDetails.POEndDate)
      {
        this.myContractDetailsForm.patchValue({
          poendDate: null
        });
      }
    }

  }
onChangeOrderDateChange(event:any){
if(this.currentDialog=="changeOrder"){
 this._changeOrder.StartDate=event;
 if(this._changeOrder.StartDate!=null && this._changeOrder.StartDate!=undefined && this._changeOrder.EndDate!=null && this._changeOrder.EndDate!=undefined){
   if( this._changeOrder.StartDate>this._changeOrder.EndDate)
   {
     this.contractsChangeOrderForm.patchValue({
       _changeOrderEndDate: null
     });
   }
 }
  if(this.startDate!=null && this._changeOrder.StartDate!=null && this._changeOrder.StartDate!=undefined ){
    if( this.startDate>this._changeOrder.StartDate)
    {
      this.contractsChangeOrderForm.patchValue({
        _changeOrderStartDate: null
      });
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Start Date should be after Start Date of Contract', detail: '' });
    }
    
  }
}
if(this.currentDialog=="Addendum"){
  this._addendumDetail.StartDate=event;
  if(this._addendumDetail.StartDate!=null && this._addendumDetail.StartDate!=undefined && this._addendumDetail.EndDate!=null && this._addendumDetail.EndDate!=undefined ){
    if( this._addendumDetail.StartDate>this._addendumDetail.EndDate)
    {
      this.contractsAddendumDetailsForm.patchValue({
        _addendumDetailEndDate: null
      });
    }
    
  }
  if(this.startDate!=null &&this.startDate!=undefined && this._addendumDetail.StartDate!=null && this._addendumDetail.StartDate!=undefined ){
    if( this.startDate>this._addendumDetail.StartDate)
    {
      this.contractsAddendumDetailsForm.patchValue({
        _addendumDetailStartDate: null
      });
    this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Start Date should be after Start Date of Contract', detail: '' });
    }
    
  }
}
}
onEndDateChange(event:any){
  this.checkContractEndDate(event);
  let endDate=event;
  if(this.contractDetails.StartDate != null && this.contractDetails.StartDate != undefined && endDate != null && endDate != undefined){
    this.contractDetails.Term=this.calDateDifference();
  }
}

calculateValuePerYearOnValueChange(event:any){
  this.contractDetails.TotalValue=event;
  if(this.contractDetails.Term!=null && this.contractDetails.Term!=undefined && event!=null && event!=undefined )
  {
    this.CalculateValuePerYear(event);
  }
}

calculateValuePerYearOnTermChange(event:any){
  if(this.contractDetails.TotalValue!=null && this.contractDetails.TotalValue!=undefined && this.differenceYears!=null && this.differenceYears!=undefined )
  {
    this.CalculateValuePerYear(this.contractDetails.TotalValue);
  }
}

  CalculateValuePerYear(value:any){
  if(this.differenceYears == 0 && this.differenceMonths==0 && this.differenceDays>0){
    this.differenceMonths=1;
  }
 if(this.differenceYears == 0 && this.differenceMonths<=12)
 {
  this.contractDetails.ValuePerYear=value;
 }
 else{
  const totalMonths = this.differenceYears * 12 + this.differenceMonths;
  if (totalMonths !== 0) {
    this.contractDetails.ValuePerYear = value / totalMonths * 12;
    this.contractDetails.ValuePerYear= Math.round(this.contractDetails.ValuePerYear * 100) / 100;
  } else {
    this.contractDetails.ValuePerYear = 0; // Handle divide by zero or invalid input
  }
}
}





//--------------------Namita new code--------------------------
uploadSOWFile()  
{  
  this.loaderService.showLoader();
      let utcDateTimeNow =  new Date().toISOString();
      this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
     // upload file into the server
      if (this.uploadedSOWFileData) {

       const formData = new FormData();
       formData.append('file', this.uploadedSOWFileData,"Contracts/" + this.timeStamp+"_"+this.uploadedSOWFileData.name);

       this._fileService.UploadSingleFile(formData).subscribe((res: any) => {
        if (res)  {  // file uploaded successfully into the file system
          this.contractDetails.SowFileName = this.uploadedSOWFileData.name;
          this.contractDetails.SowFilePath ="Contracts/"+this.timeStamp+"_"+this.contractDetails.SowFileName;
        
            this.SaveWithoutSowFile();
          }
          else{
            this.loaderService.hideLoader();
            this.messageService.add({  key: 'tc',severity: 'warn', summary: 'SOW Failed to save', detail: '' });
          }

        });
      }
      else{
        this.SaveWithoutSowFile();
      }
      
  }


  SaveWithoutSowFile(){
    this.loaderService.showLoader();
    if (this.uploadedPOFileData) {

      const formData = new FormData();
      formData.append('file', this.uploadedPOFileData,"Contracts/" + this.timeStamp+"_"+this.uploadedPOFileData.name);

      this._fileService.UploadSingleFile(formData).subscribe((res: any) => {
       if (res)  {  // file uploaded successfully into the file system
        this.contractDetails.POFileName = this.uploadedPOFileData.name;
        this.contractDetails.POFilePath = "Contracts/"+this.timeStamp+"_"+this.contractDetails.POFileName;
     
        this.saveWithoutPO();
         }
         else{
          this.loaderService.hideLoader();
           this.messageService.add({  key: 'tc',severity: 'warn', summary: 'PO Failed to save', detail: '' });
         }

       });
     }
 else{
  this.saveWithoutPO();
 }
  }


  saveWithoutPO(){
    this.loaderService.showLoader();
    if (this._contractBillingTypes.BillingTypeId && this._contractBillingTypes.BillingTypeId.length > 0) {               
      for(var i=0; i<= this._contractBillingTypes.BillingTypeId.length-1; i++)
      {
        let selectedBillingTypeId = this._contractBillingTypes.BillingTypeId[i];
        this._contractBillingTypestosave.BillingTypeId= selectedBillingTypeId;
        this._contractBillingTypesDetails.push(this._contractBillingTypestosave);
        this._contractBillingTypestosave = new ContractBillingTypes();
      }                                   
     this.contractDetails.BillingTypes = this._contractBillingTypesDetails;
     this.contractDetails.StartDate = this.datepipe.transform(this.contractDetails.StartDate, "MM/dd/yyyy");
     this.contractDetails.EndDate = this.datepipe.transform(this.contractDetails.EndDate, "MM/dd/yyyy");
     this.contractDetails.POStartDate = this.datepipe.transform(this.contractDetails.POStartDate, "MM/dd/yyyy");
     this.contractDetails.POEndDate = this.datepipe.transform(this.contractDetails.POEndDate, "MM/dd/yyyy");
  }
    if ((this.uploadedMultipleChangeOrderFileData.length >0 && this._changeOrders.length >0) || (this.uploadedMultipleAddendumFileData.length >0 && this._addendumDetails.length >0) ){
      this._fileService.UploadMultipleFile(this.formData).subscribe((response: any) => {
      if (response)
      {  
       
        this.saveWithoutCOAndAddendum();
      }
      else
      {
        this.loaderService.hideLoader();
          this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Change Order Failed to save', detail: '' })
      }
    });
    
  }
  else{
    this.saveWithoutCOAndAddendum();
}
  }



  saveWithoutCOAndAddendum(){
         // file uploaded successfully into the s3 bucket
          this.loaderService.showLoader();
          this.contractDetails.createdBy=this.privilegeService.getLoginUserId();
          this.contractDetails.createdDate=new Date();
          this.contractDetails.updatedBy=this.privilegeService.getLoginUserId();;
          this.contractDetails.updatedDate=new Date();
          this.contractDetails.isDeleted=false;    
          this.contractDetails.SponsorStakeholderDetails=this.stakeholdercontactData.filter(record => record.id !== null && record.id !==0);
          this.contractDetails.SupplierStakeholderDetails=this.stakeholdersuppliercontactData.filter(record => record.id !== null && record.id !==0);
          if(this.isEngagementDisabled){
            this.contractDetails.EngagementId=this.editedEngagement
          }
          else{
            this.contractDetails.EngagementId=this.selectedEngagement; 
          }
         
         

         this._changeOrders.forEach((Co=>{
          Co.StartDate = this.datepipe.transform(Co.StartDate, "MM/dd/yyyy");
          Co.EndDate = this.datepipe.transform(Co.EndDate, "MM/dd/yyyy");
         }));
         this._addendumDetails.forEach((addendum=>{
          addendum.StartDate = this.datepipe.transform(addendum.StartDate, "MM/dd/yyyy");
          addendum.EndDate = this.datepipe.transform(addendum.EndDate, "MM/dd/yyyy");
         }));
         this.contractDetails.ChangeOrderDetails= this._changeOrders;
         this.contractDetails.AddendumDetails= this._addendumDetails;

       this.contractDetails.ChangeOrderDetails = this._changeOrders.map(changeOrder => ({
        ...changeOrder,
        currencyId: (changeOrder.currencyId === undefined || changeOrder.currencyId === null)
          ? this.contractDetails.CurrencyId
          : changeOrder.currencyId
      }));
      
        const selectedCurrency = this.currenciesData.find(currency => currency.id === this.contractDetails.CurrencyId);
        if (selectedCurrency) {
        this.currency = selectedCurrency.name;
          } 
         this._contractService.saveContractData(this.contractDetails).subscribe(
          res=>{
            
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Contract Information has been saved',
              detail: '',
          });
          
          if(this.isEngagementDisabled){
            this.tabAndStateService.setPreviousState('Contracts', 2);
            const breadcrumb = this._enumBreadcrumb.EVSUP
            this.router.navigate(['/viewcontract'], { queryParams: { ContractId: res.data, supplierId: parseInt(this.editedEngagement), breadcrumb, currency: this.currency } });
          }
          else{
            this.router.navigate(['/viewcontract'], { queryParams: {ContractId: res.data,currency: this.currency}});
          }
          
          this.loaderService.hideLoader();
          this.compareModels();
          }
        );
  }

//-------------------end--------------------------------------------

  uploadPOFile()  
{  
  
  this.loaderService.showLoader();
      let utcDateTimeNow =  new Date().toISOString();
      this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
     // upload file into the server
      if (this.uploadedPOFileData) {

       const formData = new FormData();
       formData.append('file', this.uploadedPOFileData,"Contracts/" + this.timeStamp+"_"+this.uploadedPOFileData.name);

       this._fileService.UploadSingleFile(formData).subscribe((res: any) => {
        if (res)  {  // file uploaded successfully into the file system
         
          }
          else{
            this.messageService.add({  key: 'tc',severity: 'warn', summary: 'SOW Failed to save', detail: '' });
          }

        });
      }
      this.loaderService.hideLoader();
  }
  isSaveBtnDisable = false;
    saveContractData() {
      this.submitted = true;
      this.isSaveBtnDisable = true;
      setTimeout(() => {
        this.isSaveBtnDisable = false;
      }, 3000);
      this.loaderService.showLoader();
          if (this.myContractDetailsForm.valid && !this.uniqueMessage) {
    
            this.sDate=this.contractDetails.StartDate;
            this.eDate=this.contractDetails.EndDate;
           if(this.sDate>this.eDate){
            this.loaderService.hideLoader();
            this.messageService.add({  key: 'tc',severity: 'error', summary: 'Start Date should be less than the End Date', detail: '' });
           }
           else{
       
           this.executeCanExist = false;
            this.uploadSOWFile();
       }
      }
      else{
        this.loaderService.hideLoader();
        this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });  
         }
    }

    BackToListPage(){
      if (!this.checkIfAnyControlsTouched(this.myContractDetailsForm) && !this.checkIfAnyControlsTouched(this.contractsChangeOrderForm) && !this.checkIfAnyControlsTouched(this.contractsAddendumDetailsForm) ) 
      {
        this.executeCanExist=false;
      }
      else{
        this.executeCanExist=true;
      }

 
      if(this.editedEngagement==undefined)
      {
      this.router.navigate(['/contracts']);
      }
      else{
        this.tabAndStateService.setPreviousState('Contracts', 2);
        this.router.navigate(['/ViewEngagement', this.editedEngagement]);
      }
    }

    infoChangeorderMessageDisplayed:any
  showDialog() {
    if (this.infoChangeorderMessageDisplayed) {
      return;
   }
    this.showAttachedFile=false
    this.showAttachmentButton=true;
    this.selectedItem = null;
    this.currentDialog = 'changeOrder';
      this.resetChangeOrderForm();
      this.changeOrderSaved=false;
     this.GetStatusMasterData(3,'Change Order'); 
     this._changeOrder= new ContractChangeOrder();
     this._changeOrder.currency=this.contractCurrency;
      this.displayChangeOrderDialog = true;
      this.ChangeOrderTitle='Add Change Order';
    }
 
    infoAddendumMessageDisplayed:any;
   showDialogAdendum()
   {
    if (this.infoAddendumMessageDisplayed) {
      return;
  }
    this.addAddendumShowAttachmentButton = true;
    this.addAddendumShowAttachedFile= false;
    this.selectedItem = null;
    this.currentDialog = 'Addendum';
    this.visible = true;
      this.resetAddendumDetailsForm();
      this.addendumDetailsSaved=false;
    this.GetStatusMasterData(3,'Addendum'); 
     this._addendumDetail= new ContracAddendumDetails();
     this._addendumDetail.EndDate =this.contractDetails.EndDate; 
      this.displayAdendumDialog = true;
      this.AddendumTitle='Add Addendum';
   }
   resetChangeOrderForm(){
    this.changeOrderSaved = false;
    this.contractsChangeOrderForm.reset();
    this.contractsChangeOrderForm.patchValue({
      _changeOrderfileSource: '',
      _changeOrderFileName:''
      })
   }
   resetAddendumDetailsForm(){
    this.addendumDetailsSaved = false;
    this.contractsAddendumDetailsForm.reset();
    this.contractsAddendumDetailsForm.patchValue({
      _addendumDetailfileSource: '',
      _addendumDetailFileName: ''
  })
   }

   uploadedFileName:any="";
   uploadedPOFileName:any='';
onSowFileChange(fileDetails:any) {
    this.maxFileSize = this._fileService._fileSize;
       if(fileDetails.length > 0) 
       {
        let currentFileSize=fileDetails[0].size;
        this.uploadedFileName = fileDetails[0].name;
      
       
        let uploadedExtension = this.uploadedFileName.split('.').pop().trim().toLowerCase();
     
         // Check if the uploaded file's extension is allowed
         if (this._fileService.allowedExtensions.includes(uploadedExtension))  {
        if(currentFileSize<=this.maxFileSize)
         {
             this.myContractDetailsForm.patchValue({
              sowFileName: fileDetails[0].name,
             })
            this.uploadedSOWFileData = fileDetails[0];
      }
     
      else{
         this.myContractDetailsForm.patchValue({
          sowFileName: '',
           sowfileSource: ''
       })
       this.uploadedFileName='';
       this.messageService.add({  key: 'tc',severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
     }
    }
     else {
      this.myContractDetailsForm.patchValue({
        sowFileName: '',
         sowfileSource: ''
     })
     this.uploadedFileName='';
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
    }
  
  }
}


deleteSOWFile(){
  
  this.uploadedFileName="";
  this.uploadedSOWFileData=null;
  this.myContractDetailsForm.patchValue({
    sowFileName: '',
     sowfileSource: ''
 })
}
deletePOFile(){
  this.uploadedPOFileName="";
  this.uploadedPOFileData=null;
  this.myContractDetailsForm.patchValue({
    poFileName: '',
    pofileSource: ''
})
}

onPOFileChange(fileDetails:any) {

  this.maxFileSize = this._fileService._fileSize;
  if(fileDetails.length > 0) 
  {

   let currentFileSize=fileDetails[0].size;
   let uploadedPOFileName = fileDetails[0].name;
   this.uploadedPOFileName=uploadedPOFileName;
   
   let uploadedExtension = uploadedPOFileName.split('.').pop().trim().toLowerCase();

    // Check if the uploaded file's extension is allowed
    if (this._fileService.allowedExtensions.includes(uploadedExtension))  {
   if(currentFileSize<=this.maxFileSize)
    {
        this.myContractDetailsForm.patchValue({
         poFileName: fileDetails[0].name,
        })
       this.uploadedPOFileData = fileDetails[0];
 }

 else{
    this.myContractDetailsForm.patchValue({
      poFileName: '',
      pofileSource: ''
  })
  this.uploadedPOFileName='';
  this.messageService.add({  key: 'tc',severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
}
}
else {
 this.myContractDetailsForm.patchValue({
  poFileName: '',
  pofileSource: ''
})
this.uploadedPOFileName='';
 this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
}

}

}
onChangeOrderFileChange(fileDetails: any) {
  let uploadedChangeOrderFile: any;
  this.maxFileSize = this._fileService._fileSize; // 20 MB (value Bytes in binary)
  
  // Check if any files were selected
  if (fileDetails.length > 0) {
    let currentFileSize = fileDetails[0].size;
    let uploadedFileName = fileDetails[0].name;
    let allowedExtensions = [FileExtension.DOC,FileExtension.DOCX,FileExtension.PDF,FileExtension.XLS,FileExtension.XLSX];
   let uploadedExtension = uploadedFileName.split('.').pop().trim().toLowerCase();

    // Check if the uploaded file's extension is allowed
    if (allowedExtensions.includes(uploadedExtension))  {
      if (currentFileSize <= this.maxFileSize) {
        this.contractsChangeOrderForm.patchValue({
          _changeOrderFileName: fileDetails[0].name,
        });
        uploadedChangeOrderFile = fileDetails[0];

        this.uploadedMultipleChangeOrderFileData.push(uploadedChangeOrderFile);
        this.timeStamp = "";
        let utcDateTimeNow = new Date().toISOString();
        this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
        this.formData.append("file", uploadedChangeOrderFile, "Contracts/ChangeOrders/" + this.timeStamp + "_" + uploadedChangeOrderFile.name);

        // Update File name / file relative path for UI and DB
        this._changeOrder.fileName = uploadedChangeOrderFile.name;
        this._changeOrder.filePath = "Contracts/ChangeOrders/" + this.timeStamp + "_" + this._changeOrder.fileName;
          this.showAttachmentButton = false;
          this.showAttachedFile = true;
      } else {
        this.contractsChangeOrderForm.patchValue({
          _changeOrderFileName: '',
          _changeOrderfileSource: ''
        });
        this._changeOrder.fileName = '';
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
      }
    } else {
      this.contractsChangeOrderForm.patchValue({
        _changeOrderFileName: '',
        _changeOrderfileSource: ''
      });
      this._addendumDetail.fileName ='';
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
    }
  }
}

onAddendumFileChange(fileDetails:any) {
  
  let uploadedAddendumFile: any;

  this.maxFileSize=this._fileService._fileSize; // 20 MB  (value Bytes  in binary)
    // file upload on change event method
       if(fileDetails.length > 0) 
       {
        let currentFileSize = fileDetails[0].size;
        let uploadedFileName = fileDetails[0].name;
        let allowedExtensions = [FileExtension.DOC,FileExtension.DOCX,FileExtension.PDF,FileExtension.XLS,FileExtension.XLSX];
        let uploadedExtension = uploadedFileName.split('.').pop().trim().toLowerCase();

          if (allowedExtensions.includes(uploadedExtension)) {
          if(currentFileSize<=this.maxFileSize)
          {
             this.contractsAddendumDetailsForm.patchValue({
              _addendumDetailFileName: fileDetails[0].name,
             })
             uploadedAddendumFile = fileDetails[0];
          
          this.uploadedMultipleAddendumFileData.push(uploadedAddendumFile);
          this.timeStamp ="";
          let utcDateTimeNow =  new Date().toISOString()
          this.timeStamp = formatDate(utcDateTimeNow, this._dateformart.FiletimeStamp, this._dateformart.TimeStampZone);
          this.formData.append("file",  uploadedAddendumFile,"Contracts/Addendums/"+this.timeStamp+"_"+uploadedAddendumFile.name);
         
          // update File name / file relative path for UI and DB 
          this._addendumDetail.fileName = uploadedAddendumFile.name;
          this._addendumDetail.filePath ="Contracts/Addendums/"+this.timeStamp+"_"+this._addendumDetail.fileName;
          this.addAddendumShowAttachmentButton = false;
          this.addAddendumShowAttachedFile= true;
      }
      else{
         this.contractsAddendumDetailsForm.patchValue({
          _addendumDetailFileName: '',
         _addendumDetailfileSource: ''
       })
       this._addendumDetail.fileName ='';
       this.messageService.add({  key: 'tc',severity: 'warn', summary: 'File Size limited to 20 Mb', detail: '' });
     }
     }
     else {
      this.contractsAddendumDetailsForm.patchValue({
        _addendumDetailFileName: '',
       _addendumDetailfileSource: ''
     })
     this._addendumDetail.fileName ='';
      this.messageService.add({ key: 'tc', severity: 'error', summary: 'Invalid file type. Only .pdf, .xls, .xlsx, .doc, .docx files are allowed.', detail: '' });
    }
}
}

checkIfAnyControlsTouched(formGroup: FormGroup) {
  for (const controlName in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(controlName)) {
          const control = formGroup.controls[controlName];
          if (control.touched) {
              return true; // If any control is touched, return true
          }
      }
  }
  return false; // No controls have been touched
}
cancelDialog(isCancelButton:boolean,buttonClicked: string){
  let dilalogFormName:any;
    if (buttonClicked === 'Change Order')
    {
      dilalogFormName = this.contractsChangeOrderForm;
    }
    else
    {
      dilalogFormName =this.contractsAddendumDetailsForm
    }
  const anyControlsTouched = this.checkIfAnyControlsTouched(dilalogFormName);
  if (anyControlsTouched) {
    if(isCancelButton)
    {
      let detailMessage = "";
      if (buttonClicked === 'Change Order') {
        this.displayChangeOrderDialog = false;
        detailMessage = "Change Order has not been saved";
      } else if (buttonClicked === 'Adendum') {
        this.displayAdendumDialog = false;
        detailMessage = "Addendum has not been saved";
      }
      this.messageService.add({
        key: 'tc',
        severity: 'info',
        summary: detailMessage,
        detail: '',
    });
    }
  }
  this.displayAdendumDialog = false;
  this.displayChangeOrderDialog = false;
  }
  GetStatusMasterData(enumId:number,type:string='') {
   
    this.masterDataService.getCodeListData(enumId,type).subscribe(
      res=>{
        this.changeOrderStatusDataSource   =res.data;
         this.changeOrderDataSource =  res.data;
         this.addendumDetailsDataSource=res.data;
         this.addendumDetailStatusDataSource=res.data;
      }  
    );
  
  };
  isSaveChangeOrderPopupBtnDisable = false;
  selectedCurrency:any
 saveChangeOrderData() {
      this.isSaveChangeOrderPopupBtnDisable = true;
      setTimeout(() => {
        this.isSaveChangeOrderPopupBtnDisable = false;
      }, 3000);
      let anyControlsTouched = this.checkIfAnyControlsTouched(this.contractsChangeOrderForm);
      this.loaderService.showLoader();
      this.changeOrderSaved = true;
      if (this.contractsChangeOrderForm.valid && this._changeOrder.statusMappingId>0) {
  
        if (this.selectedItem != null) {
          const contactIndex = this._changeOrders.findIndex(item => item.title === this.oldSelectedItem.title && item.StartDate === this.oldSelectedItem.StartDate && item.EndDate === this.oldSelectedItem.EndDate && item.value === this.oldSelectedItem.value && item.currency === this.oldSelectedItem.currency && item.status === this.oldSelectedItem.status);
  
          if (contactIndex !== -1) {
            let selectedChangeOrderStatus = this.changeOrderDataSource?.filter(t => t.id == this._changeOrder.statusMappingId);
            if (selectedChangeOrderStatus.length > 0 && selectedChangeOrderStatus[0].name != undefined)
            
              this._changeOrder.status = selectedChangeOrderStatus[0].name;
  
            this._changeOrders[contactIndex] = { ...this._changeOrder };
  
            this.selectedItem = null;
            this.oldSelectedItem = null;
            this.changeOrderValue = this._changeOrder.value;
            this.onChangeOrderValueChange();
          }
          this.displayChangeOrderDialog = false;
          this.messageService.add({
            key: 'tc',
            severity: 'success',
            summary: 'Change Order has been saved',
            detail: '',
          });
        } else {
          let selectedChangeOrderStatus = this.changeOrderDataSource?.filter(t => t.id == this._changeOrder.statusMappingId);
          if (selectedChangeOrderStatus.length > 0 && selectedChangeOrderStatus[0].name != undefined)
            this._changeOrder.status = selectedChangeOrderStatus[0].name;
  
          if (this.contractCurrency != null && this.contractCurrency != undefined) {
            let selectedCurrencyCode = this.currenciesData?.filter(t => t.name == this._changeOrder.currency);
            if (selectedCurrencyCode.length > 0 && selectedCurrencyCode[0].name != undefined)
              this._changeOrder.currency = selectedCurrencyCode[0].code;
            this._changeOrder.currencyId = selectedCurrencyCode[0].id;
            this.selectedCurrency=selectedCurrencyCode[0].name
          }
  
          this.changeOrderValue = this._changeOrder.value;
          this._changeOrders.push(this._changeOrder);
          this.onChangeOrderValueChange();
          this.displayChangeOrderDialog = false;
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Change Order has been saved', detail: '' });
        }
        if (this._changeOrder && this._changeOrder.EndDate && this.contractDetails && this.contractDetails.EndDate) {
          if (this._changeOrder.EndDate > this.contractDetails.EndDate) {
            this.contractDetails.EndDate = this._changeOrder.EndDate;
          }
          else{   
            if(this._changeOrders.length > 0)
            {   
            const highestEndDate = this._changeOrders.reduce((maxDate, order) => {
              const orderEndDate = order.EndDate ? new Date(order.EndDate) : null;     
              if (orderEndDate && orderEndDate > maxDate) {
                  maxDate = orderEndDate;
              }  
              return maxDate;
          }, new Date(0));
          if(this.contractDetails.EndDate < highestEndDate)
            this.contractDetails.EndDate = highestEndDate;   
          }
        }
      } 
    }
    else{
      this.messageService.add({  key: 'tc',severity: 'warn', summary: 'Please enter required information', detail: '' });
    }
  // }
  // else{
  //   this.displayChangeOrderDialog = false;
  // }
  this.loaderService.hideLoader();
  this.currentDialog='';
}
isSaveAddendumDataPopupBtnDisable = false;
saveAddendumData() {
  this.isSaveAddendumDataPopupBtnDisable = true;
  setTimeout(() => {
    this.isSaveAddendumDataPopupBtnDisable = false;
  }, 3000);
    let anyControlsTouched = this.checkIfAnyControlsTouched(this.contractsAddendumDetailsForm);
    this.loaderService.showLoader();
    this.addendumDetailsSaved = true;
    if (this.contractsAddendumDetailsForm.valid && this._addendumDetail.statusMappingId>0) {

      if (this.selectedItem != null) {
	   const contactIndex = this._addendumDetails.findIndex(item => item.title === this.oldSelectedItem.title && item.StartDate === this.oldSelectedItem.StartDate && item.EndDate === this.oldSelectedItem.EndDate  && item.status === this.oldSelectedItem.status) ;
        if (contactIndex !== -1) {
		 let selectedAddendumDetailsStatus = this.addendumDetailsDataSource?.filter(t=>t.id==this._addendumDetail.statusMappingId);
        if(selectedAddendumDetailsStatus.length>0 && selectedAddendumDetailsStatus[0].name != undefined)
        this.selectedItem.status = selectedAddendumDetailsStatus[0].name;     
        this._addendumDetails[contactIndex] = { ...this._addendumDetail };
        this.selectedItem = null;
        this.oldSelectedItem=null;
      }     
        this.displayAdendumDialog = false;
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Addendum has been saved',
          detail: '',
        });
      } else {

        let selectedAddendumDetailsStatus = this.addendumDetailsDataSource?.filter(t=>t.id==this._addendumDetail.statusMappingId);
      if(selectedAddendumDetailsStatus.length>0 && selectedAddendumDetailsStatus[0].name != undefined)
      this._addendumDetail.status =selectedAddendumDetailsStatus[0].name;
      this._addendumDetails.push(this._addendumDetail);
      this.displayAdendumDialog = false;
      this.messageService.add({ key: 'tc', severity: 'success', summary: 'Addendum has been saved', detail: '' });
    }
    } else {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Please enter required information', detail: '' });
    }
  // } else {
  //   this.displayAdendumDialog = false;
  // }
  this.currentDialog='';
  this.loaderService.hideLoader();
}
updateChangeOrderCurrency(selectedCurrency: any) {

  const selectedCurrencyObject = this.currenciesData.find(currency => currency.id === selectedCurrency);

  if (selectedCurrencyObject) {
    this.contractCurrency=selectedCurrencyObject.name;
  }
}



  GetChangeOrderMasterData(enumId:number,type:string='') {
    this.changeOrderDataSource = [];
    this.masterDataService.getCodeListData(enumId,type).subscribe(
      res=>{
        this.changeOrderDataSource   =res.data;
      }
    );
   
  };

  uniqueSponsorContactNameMessage=false;
 
  creContcheckSponsorContactNameExist(contactDetails:any)
  {
    if(this.contactHeader=="Add Supplier Contact"){
      if(this.supplierContactData.length>0){
        if (this.isNameRepeated(contactDetails)) {
          this.uniqueSponsorContactNameMessage=true;
        } else {
          this.uniqueSponsorContactNameMessage=false;
        }
      }
    }
    else{
    if(this.sponsorContactsDataSource.length>0){
      if (this.isNameRepeated(contactDetails)) {
        this.uniqueSponsorContactNameMessage=true;
      } else {
        this.uniqueSponsorContactNameMessage=false;
      }
    }
  }
  }
  existSponsorContact:any;
  currentSponsorContactIndex=-1;
  currentSupplierContactIndex=-1;
  isNameRepeated(contactDetails: any): boolean {
    this.uniqueSponsorContactNameMessage=false;
        let nameCount = 0;
        const contactName = contactDetails.name.toLowerCase();
        if(this.contactHeader=="Add Supplier Contact"){ 
          if(this.currentSupplierContactIndex==-1) 
          {
            
            this.supplierContactData.forEach((obj: any, index: any) => {
              if (obj.contactName.toLowerCase() === contactName) {
                  nameCount++;
                  this.existSponsorContact="Contact Name already exists in the Supplier of selected Engagement. Please enter a unique Name";
              }
          });
          }
          else if(this.currentSupplierContactIndex>=0){
            this.supplierContactData.forEach((obj: any, index: any) => {
              if (obj.contactName.toLowerCase() === contactName &&  index!=this.currentSupplierContactIndex) {
                  nameCount++;
              }
          });
          }
          return nameCount > 0;
        }
        else{
        if(this.currentSponsorContactIndex==-1) 
        {
          this.sponsorContactsDataSource.forEach((obj: any, index: any) => {
            if (obj.name.toLowerCase() === contactName) {
              this.existSponsorContact="Contact Name already exists in the Sponsor of selected Engagement. Please enter a unique Name";
              nameCount++;
               
            }
        });
        }
        else if(this.currentSponsorContactIndex>=0){
          this.sponsorContactsDataSource.forEach((obj: any, index: any) => {
            if (obj.name.toLowerCase() === contactName &&  index!=this.currentSponsorContactIndex) {
             
                nameCount++;
            }
        });
        }
  
        return nameCount > 0;
      }
    }
    confirm(item: any) {
      this.selectedItem = item;
      this.confirmationService.confirm({
          message: 'Are you sure you want to delete this change order?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.deleteItem();
            this.messageService.add({
              key: 'tc',
              severity: 'success',
              summary: 'Contact has been deleted',
              detail: '',
          });
          },
          reject: (type: any) => {
             
          }
      });
  }
  deleteItem() {
    const contactIndex = this._changeOrders.findIndex(item => item === this.selectedItem);
    if (contactIndex !== -1) {
      this._changeOrders.splice(contactIndex, 1);
      this.selectedItem = null;
    }
  }

  editChangeOrder(item:any){
    this.showAttachedFile=true;
    this.showAttachmentButton=false;
   this.GetStatusMasterData(3,'Change Order'); 
   this._changeOrder=new ContractChangeOrder()
    this.displayChangeOrderDialog = true;
    this.ChangeOrderTitle='Edit Change Order';
   
    this.selectedItem = { ...item }; 
    this.oldSelectedItem={ ...item }; 
    this._changeOrder=this.selectedItem;
    this._changeOrder._changeOrderfileSource = this._changeOrder.fileName;
    this.oldChangeOrderValue = this._changeOrder.value;

    this.contractsChangeOrderForm.patchValue({
      _changeOrderfileSource: this._changeOrder.fileName
    });

    if(this._changeOrder.fileName==""){
      this.showAttachmentButton=true;
      this.showAttachedFile=false;
    }
  }
  
  DeletChangeOrderFile(_changeOrderFileName:any,_changeOrderfileSource:any){
    this.showAttachmentButton=true;
    this.showAttachedFile=false;
    this._changeOrder.fileName = "";
    this._changeOrder.filePath ="";
    this.contractsChangeOrderForm.patchValue({
      _changeOrderfileSource: '',
      _changeOrderFileName:''
  })
          
      let filedetail1 = {
        "fileName":_changeOrderFileName,
        "filePath":_changeOrderfileSource
      };
    this.multiFileData.push(filedetail1);
     
      
  }

     canExist(): boolean | Promise<boolean> | Observable<boolean> {
      if(!this.submitted){
        if (  !this.checkIfAnyControlsTouched(this.myContractDetailsForm) && !this.checkIfAnyControlsTouched(this.contractsChangeOrderForm) && !this.checkIfAnyControlsTouched(this.contractsAddendumDetailsForm) ) 
        {
          this.executeCanExist=false;
        }
        else{
          this.executeCanExist=true;
        }
      }
     
      if (this.executeCanExist &&!(this._oldContractDetails==JSON.stringify(this.contractDetails)))
      {
        return new Promise<boolean>((resolve) => {
          this.confirmationService.confirm({
            message: 'Contract will not be saved, Continue?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.messageService.add({
                key: 'tc',
                severity: 'info',
                summary: 'Contract not saved',
                detail: '',
            });
              resolve(true); 
            },
            reject: () => {
              resolve(false); 
            },
          });
        });
      }
      else{
        return true;
      }
      
    }
    generateBreadcrumb()
    {
      if(this.editedEngagement == undefined)
      {
      this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.CCON);
      }
      else
      {
        this.breadcrumbService.generateBreadcrumb(this._enumBreadcrumb.ENGCONL,this.editedEngagementName,"Contracts","",this.editedEngagement);
      }
    }
    getBreadcrumbs(): MenuItem[] {
      return this.breadcrumbService.getBreadcrumbs();
    }
    noOnlySpacesValidator(control: AbstractControl): ValidationErrors | null {
      if (control.value && control.value.trim().length === 0) {
        return { onlySpaces: true };
      }
      return null;
    }
    EditAddendumTile(item:any){
      this.addAddendumShowAttachmentButton = false;
      this.addAddendumShowAttachedFile= true;
      this.GetStatusMasterData(3,'Addendum'); 
      this._addendumDetail=new ContracAddendumDetails()    
      this.displayAdendumDialog = true;
      this.selectedItem = { ...item }; 
      this.oldSelectedItem={ ...item }; 
      this._addendumDetail=this.selectedItem;
      this.AddendumTitle='Edit Addendum';
      this._addendumDetail._addendumDetailfileSource = this._addendumDetail.fileName;
      this.contractsChangeOrderForm.patchValue({
        _addendumDetailfileSource: this._addendumDetail.fileName
      });

      if(this._addendumDetail.fileName==""){
        this.addAddendumShowAttachmentButton=true;
        this.addAddendumShowAttachedFile=false;
      }
    }
    DeletAddendumFile(_addendumDetailFileName:any,_addendumDetailfileSource:any){
       const fileNameToRemove = this._addendumDetail.fileName;
       const indexToRemove = this.uploadedMultipleChangeOrderFileData.findIndex(file => file.name === fileNameToRemove);
       if (indexToRemove !== -1) {
         this.uploadedMultipleChangeOrderFileData.splice(indexToRemove, 1);
       }
       this.addAddendumShowAttachmentButton = true;
       this.addAddendumShowAttachedFile= false;
       this._addendumDetail.fileName = "";
       this._addendumDetail.filePath ="";
       this.contractsAddendumDetailsForm.patchValue({
        _addendumDetailfileSource: '',
        _addendumDetailfileName:'' 
     })           
         let filedetail1 = {
           "fileName":_addendumDetailFileName,
           "filePath":_addendumDetailfileSource
         };
       this.multiFileData.push(filedetail1);    
     }  
     validateMaxSelections(control:any) {
      const selectedOptions = control.value;
      if (selectedOptions && selectedOptions.length > 2) {
          return { maxSelectionsExceeded: true };
      }
      return null;
  }
  getCurrencyType(currencyName: string): CurrencyType | undefined {
    switch (currencyName) {
      case 'United States Dollar':
        return CurrencyType.USD;
      case 'Indian Rupee':
        return CurrencyType.INR;
      case 'Chinese Yuan':
        return CurrencyType.CYN;
      case 'Japanese Yen':
        return CurrencyType.JPY;
      default:
        return undefined;
    }
  }
  notOnlySpacesValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value.trim() === '') {
       return { 'onlySpaces': true };
     }
     return null;
   }

saveContext: string = '';

openContactModel(header: number, saveContext: string) {
this.contactHeader=header==1 ? 'Add Supplier Contact' : 'Add Sponsor Contact';  
this.uniqueSponsorContactNameMessage=false;
this.existSponsorContact="";
  if (this.contractDetails.EngagementId) {
    this.dialogFormGroup.reset();
    Object.keys(this.dialogFormGroup.controls).forEach(key => {
      this.dialogFormGroup?.get(key)?.setErrors(null);
    });
  
    
    this._supplierContact= new SupplierContact();
    this.saveContext = saveContext; // Set the save context
    this.contactSubmitted = false;
  
    // Create a new instance of sponserContact
    this.sponserContact = {
      name: "",
      designation: "",
      countryCode: "",
      phoneNumber: "",
      email: ""
    };

    this.displayDialog = true;
  } else {
    this.messageService.add({
      key: 'tc',
      severity: 'warn',
      summary: 'Please select an Engagement First.',
      detail: '',
    });
  }
}

displayContractGroupDialog:boolean=false;

openContractGroupModel() {
  this.contactHeader = 'Add Function';
  if (this.contractDetails.EngagementId) {
    this.resetForm(this.contactFormGroup);
    this.contractGroupSubmitted = false;

    this.contractGroup = {
      name: ""
    };

    this.displayContractGroupDialog = true;
  } else {
    this.showWarningMessage('Please select an Engagement First.');
  }
}
resetForm(formGroup: FormGroup) {
  formGroup.reset();
  Object.keys(formGroup.controls).forEach(key => {
    formGroup.get(key)?.setErrors(null);
  });
}
showWarningMessage(summary: string) {
  this.messageService.add({
    key: 'tc',
    severity: 'warn',
    summary: summary,
    detail: '',
  });
}


  _supplierContact= new SupplierContact();
  _supplierContacts: SupplierContact[]=[];
  contactHeader='';
  
  closeDialog(){
    this.displayDialog = false;
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'Contact Information has not been saved',
      detail: '',
  });
    
  }

  closeContractGroupDialog(){
    this.displayContractGroupDialog = false;
    this.messageService.add({
      key: 'tc',
      severity: 'info',
      summary: 'Function Information has not been saved',
      detail: '',
  });
    
  }
  supplierContactList:SupplierContact[]=[];
  sponserContactList:ITbl_SponserContacts[]=[];
  newSponsorConatactId:number=0;
  SaveSponsorContact(){
    this.contactSubmitted=true;
    this.trimInput();
    if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage) {
    this.contactSubmitted=false;
    this.sponserContact.createdBy= this.privilegeService.getLoginUserId();
    this.sponserContact.createdDate=new Date();
    this.sponserContact.updatedBy=this.privilegeService.getLoginUserId();
    this.sponserContact.updatedDate=new Date();
    this.sponserContact.isDeleted=false;
    this.sponserContact.isSendNotification=false;
    this.sponserContact.id=0;
  if (this.supplierData) {
    this.sponserContact.createdBy=this.privilegeService.getLoginUserId();
    this.sponserContact.sponserId=this.sponsorId;
    this.sponserContactList.push(this.sponserContact);
    this.filteredSponsorContacts.push(this.sponserContact)
    this.filteredStakholderSponsorContacts.push(this.sponserContact)
    this.masterDataService.AddSponsorContact(this.sponserContact).subscribe(
      data=>{
        this.newSponsorConatactId=data;
        this.masterDataService.getSponserData(this.sponsorId).subscribe(res=>{
        this.sponsorContactsDataSource= res.data.sponserContacts;
        this.filteredStakholderSponsorContacts= this.sponsorContactsDataSource;
        this.totalSponsorContact=this.sponsorContactsDataSource;
        this.ExcludeStakeholderContacts('sponsor',this.newSponsorConatactId);
        this.sponsorContacts=res.data.sponserContacts;
          this.contractDetails.SponserContactId = this.sponsorContactsDataSource[this.sponsorContactsDataSource.length - 1].id;
          const sponsorContact = this.sponsorContactsDataSource[this.sponsorContactsDataSource.length - 1];
            this.sponserContactList = [sponsorContact];
        });

        this.displayDialog = false;
        this.contactHeader=''
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Contact has been saved',
          detail: '',
      });
      this.saveEngagmentSponsorContact();
      this.dropdownValueChanged=false;
      }
    );
   }
 }

  }

  selectedSponsorContact:any;
  SaveStakeholderSponsorContact(){
    this.contactSubmitted=true;
    this.trimInput();
    if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage) {
    this.contactSubmitted=false;
    this.sponserContact.createdBy= this.privilegeService.getLoginUserId();
    this.sponserContact.createdDate=new Date();
    this.sponserContact.updatedBy=this.privilegeService.getLoginUserId();
    this.sponserContact.updatedDate=new Date();
    this.sponserContact.isDeleted=false;
    this.sponserContact.isSendNotification= false;
    this.sponserContact.sponserId=this.sponsorInfo.sponserInfo.id;
    this.sponserContact.id=0;

   if(this.supplierData){
    this.masterDataService.AddSponsorContact(this.sponserContact).subscribe(res=>
      {
        this.newSponsorConatactId=res;
        this.sponserContact.id=res;
        this.masterDataService.getSponserData(this.sponsorId).subscribe(res=>{
          this.sponsorContactsDataSource= res.data.sponserContacts;
          this.filteredStakholderSponsorContacts= this.sponsorContactsDataSource;
          this.totalSponsorContact=this.sponsorContactsDataSource;
          this.sponsorContacts=res.data.sponserContacts;
          this.ExcludeStakeholderContacts('sponsor',0);
          this.updateSponsorDropdownOptions();
         
        });
        this.displayDialog = false;
        this.contactHeader=''
        this.messageService.add({
          key: 'tc',
          severity: 'success',
          summary: 'Contact has been saved',
          detail: '',
      });
    
         this.saveEngagmentSponsorContact();
      }
    );


   }
    this.displaySponsorStakeholder=false;
 }
  }
  selectedSupplierContact:any;
  newSupplierConatactId:number=0;
  SaveStakeholderSupplierContact(){
    this.contactSubmitted=true;
    this.trimInput();
    if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage) {
    this.contactSubmitted=false;
   this._supplierContact.contactName=this.sponserContact.name;
   this._supplierContact.designation=this.sponserContact.designation;
   this._supplierContact.phoneNumber=this.sponserContact.phoneNumber;
   this._supplierContact.countryCode=this.sponserContact.countryCode;
   this._supplierContact.email=this.sponserContact.email;
   this._supplierContact.supplierProfileId=this.supplierId;
   this._supplierContact.isSendNotification= false;

   if(this.supplierData){
    this._supplierProfile=this.supplierData;
     this._supplierProfile.engagementStartDate = this.datepipe.transform(this._supplierProfile.engagementStartDate, "MM/dd/yyyy");
        this._mastersupplierService.AddSupplierContact(this._supplierContact).subscribe(res =>
          {
         this.newSupplierConatactId=res;
         this._supplierContact.id=res;
         this._supplierContact.id=this.newSupplierConatactId;
         this._mastersupplierService.getSupplierById(this.supplierId).subscribe(
          res => {
        this.supplierContactData = res.data.supplierContacts;
        this.filteredStakholderSupplierContacts=this.supplierContactData;
        this.supplierContacts=res.data.supplierContacts;
        this.totalSupplierContacts=this.supplierContacts;
         this.ExcludeStakeholderContacts("supplier",0);
        this.updateDropdownOptions();
          })
        
         this.contactHeader='';
         this.displayDialog = false;
          this.messageService.add({ 
              key: 'tc', 
              severity: 'success',
              summary: 'Contact has been saved',
              detail: '',
              });
          this.saveEngagmentSupplier();
      
         
      this.contactHeader='';
      this.displayDialog = false;
    });
   
  
   }
    }
  }
  isSavePopupBtnDisable = false;
  SaveContact() {
    this.isSavePopupBtnDisable = true;
    setTimeout(() => {
      this.isSavePopupBtnDisable = false;
    }, 3000);
    if (this.saveContext === 'StakeholderSponsor') {
      this.SaveStakeholderSponsorContact();
    } else if (this.saveContext === 'StakeholderSupplier') {
      this.SaveStakeholderSupplierContact();
    } else if (this.saveContext === 'Sponsor') {
      this.SaveSponsorContact();
    } else if (this.saveContext === 'Supplier') {
      this.SaveSupplierContact();
    }
  }
  _supplierProfile=new SupplierProfile();

SaveSupplierContact() {
  this.contactSubmitted = true;
  this.trimInput();
  if (this.dialogFormGroup.valid && ! this.uniqueSponsorContactNameMessage) {
    this.contactSubmitted = false;

    const newSupplierContact = {
      id:0,
      supplierProfileId:this.supplierId,
      contactName: this.sponserContact.name,
      designation: this.sponserContact.designation,
      phoneNumber: this.sponserContact.phoneNumber,
      countryCode: this.sponserContact.countryCode,
      email: this.sponserContact.email,
      createdBy:this.privilegeService.getLoginUserId(),
      isSendNotification: false
    };

    // Check if supplierData and supplierContacts are both defined
    if (this.supplierContacts) { 
        this.supplierContactList.push(newSupplierContact);
        this.filteredSupplierContacts.push(newSupplierContact);
        this.filteredStakholderSupplierContacts.push(newSupplierContact);
          this._mastersupplierService.AddSupplierContact(newSupplierContact).subscribe(res => {
            this.newSupplierConatactId=res;
            this._mastersupplierService.getSupplierById(this.supplierId).subscribe(
            res => {
              if (res.data && res.data.supplierContacts) {
                this.supplierContactData = res.data.supplierContacts;
                this.filteredStakholderSupplierContacts=this.supplierContactData;
                this.supplierContacts=res.data.supplierContacts;
                this.totalSupplierContacts=this.supplierContacts;
                this.ExcludeStakeholderContacts('supplier',this.newSupplierConatactId);
                this.contractDetails.OwnerSupplierContactId = this.supplierContactData[this.supplierContactData.length - 1].id;
                const supplierContact = this.supplierContactData[this.supplierContactData.length - 1];
                this.supplierContactList = [supplierContact];
              }
            }
          );
          this.messageService.add({ key: 'tc', severity: 'success', summary: 'Contact has been saved', detail: '' });
          this.dropdownValueChanged=false;
          this.contactHeader = '';
          this.displayDialog = false;
          this.saveEngagmentSupplier();
        });
      
    }
  }
}

selectedContactIds: number[] = [];
filteredStakholderSupplierContacts:any;
  stakeholdercontactData:any[]=[]; 
  AddStakeholderSponsorContact(){
     this.stakeholdercontactData.unshift({
      id: 0,
      name: '',
      designation:'',
      countrycode: '',
      phonenumber:'',
      isSendnotification:false,
      isdeleted:false
    
    });
    this.stakeholdercontactData.forEach((item, index) => {
      this.sponsorContactDetails({ value: item.id }, index);
    });  
  }

 stakeholdersuppliercontactData:any[]=[];
  AddStakeholderSupplierContact(){ 
    this.stakeholdersuppliercontactData.unshift({
      id: 0,
      name: '',
      designation:'',
      countrycode: '',
      phonenumber:'',
      isSendnotification:false,
      isdeleted:false
    
    });
    this.stakeholdersuppliercontactData.forEach((item, index) => {
      this.supplierContactDetails({ value: item.id }, index);
    });
  }

  
  _contractGroup=new ContractGroupModel()

  SaveContractGroup() {
    this.contractGroupSubmitted = true;
    this.trimInput();
    if (this.contactFormGroup.valid) {
      this.loaderService.showLoader();
        this._contractGroup.name = this.contractGroup.name;
        if(this.isEngagementDisabled){
          this._contractGroup.engagementId = this.editedEngagement;
          this.selectedEngagement=this.editedEngagement;
        }
        else{
          this._contractGroup.engagementId = this.selectedEngagement;
        }
        this._contractGroup.createdBy = this.privilegeService.getLoginUserId();
        const existingRecord = this.contractGroupData.find(record => record.engagementId === this._contractGroup.engagementId && record.name.toLowerCase() === this._contractGroup.name?.toLowerCase());
        if (existingRecord ||this._contractGroup.name?.toLowerCase()=="others") {
            this.messageService.add({ key: 'tc', severity: 'warn', summary: 'This Function already exists in the selected Engagement', detail: '' });
            this.loaderService.hideLoader();
        } else {
            this.engagementService.AddContractGroup(this._contractGroup).subscribe(res => {
                this.messageService.add({ key: 'tc', severity: 'success', summary: 'Function Information has been saved', detail: '' });
                this.displayContractGroupDialog = false;
               this.GetContractGroupData(this.selectedEngagement);
               setTimeout(() => {
                this.contractDetails.OwnerFunctionId = this.contractGroupData[this.contractGroupData.length - 1].id;
                const contractGroupName = this.contractGroupData.find(contract => contract.id === this.contractDetails.OwnerFunctionId);
                this.selectedContractGroup = contractGroupName.name ? contractGroupName.name : '';

                this.loaderService.hideLoader();
               }, 1000);
              
            });
        }
    }
}
ContractGroupText(){
  this.selectedContractGroup='';
  const contractGroupName = this.contractGroupData.find(contract => contract.id === this.contractDetails.OwnerFunctionId);
  this.selectedContractGroup = contractGroupName.name ? contractGroupName.name : '';
}

  changedContractBasicInfo:any;
  changedContractChangeOrderInfo:any;
  changedContractAddendumInfo:any;
  listContractBasicContactInfo:any;
  auditModelForContracts=new AuditLogModel();
  auditModelForContractChangeOrder=new AuditLogModel();

  oldContractData:any;
  oldChanrOrderData:any;
  oldAddendumData:any;
  compareModels() {
    this.changedContractBasicInfo = this.auditLogService.compareModels(JSON.parse(this.oldContractData),this.contractDetails,'Contract Details ',this.auditIdentifierDetails.ContractDetails ,'Create','Contract service');
   
    this._changeOrders.forEach((item:any) => {
      this.changedContractChangeOrderInfo=this.auditLogService.compareModels(JSON.parse(this.oldChanrOrderData),item,'Contract Change Orders',this.auditIdentifierDetails.ContractChangeOrders,'Create','Contract service');
     
    });
   
    this._addendumDetails.forEach((item:any) => {
      this.changedContractAddendumInfo=this.auditLogService.compareModels(JSON.parse(this.oldAddendumData),item,'Contract Addendums',this.auditIdentifierDetails.ContractAddendums,'Create','Contract service');
     
    });
    
  }
  _engagmentSupplierContact=new EngagementSupplierContact();
  _engagmentSponsorContact=new EngagementSponsorContact()
  saveEngagmentSupplier()
  {
    this._engagmentSupplierContact.supplierContactId=this.newSupplierConatactId;
    this._engagmentSupplierContact.engagementId=this.selectedEngagement;
    this._engagmentSupplierContact.createdBy=this.privilegeService.getLoginUserId();
    this._engagmentSupplierContact.isSendNotification=false;
    this.engagementService.AddEngagementSupplierContact(this._engagmentSupplierContact).subscribe(res => {
});
  }

  saveEngagmentSponsorContact()
  {
    this._engagmentSponsorContact.sponsorContactId=this.newSponsorConatactId;
    this._engagmentSponsorContact.engagementId=this.selectedEngagement;
    this._engagmentSponsorContact.createdBy=this.privilegeService.getLoginUserId();
    this._engagmentSponsorContact.isSendNotification=false;
    this.engagementService.AddEngagementSponsorContact(this._engagmentSponsorContact).subscribe(res => {
});
  }
  onSponsorContactChange(event: any): void {
    this.dropdownValueChanged = false;
    const selectedId = event.value;
    const selectedContact = this.sponsorContactsDataSource.find(contact => contact.id === selectedId);
    if (selectedContact) {
      this.sponserContactList = [selectedContact];
      this.filteredSponsorContacts = this.sponsorContactsDataSource.filter(contact => contact.id !== selectedId);
    }
    this.ExcludeStakeholderContacts('sponsor',selectedId);
  }
  onSupplierContactChange(event: any): void {
    this.dropdownValueChanged = false;
    const selectedId = event.value;
    const selectedContact = this.supplierContactData.find(contact => contact.id === selectedId);
    if (selectedContact) {
      this.supplierContactList = [selectedContact];
      this.filteredSupplierContacts = this.supplierContactData.filter(contact => contact.id !== selectedId);
    }
    this.ExcludeStakeholderContacts('supplier',selectedId);
  }
  deleteSponsorContact(index:any,contractId:number){
  if(contractId>0)
    {
  this.confirmationService.confirm({
    message: 'Are you sure you want to delete this stakeholder from this Contract? Note: The stakeholder will only be deleted from this contract and will still stay under the Sponsor Contacts and Engagement Stakeholders.',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      this.stakeholdercontactData.splice(index, 1);
      this.stakeholderSponsorContacts.splice(index, 1);
      this.updateSponsorDropdownOptions();
    },
    reject: (type: any) => {
    
    }
});
    }
    else
    {
      this.stakeholdercontactData.splice(index, 1);
      this.stakeholderSponsorContacts.splice(index, 1);
    }
  }

  deleteSupplierContact(index:any,contractId:number){
    if(contractId>0)
      {    
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this stakeholder from this Contract? Note: The stakeholder will only be deleted from this contract and will still stay under the Supplier Contacts and Engagement Stakeholders.',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.stakeholdersuppliercontactData.splice(index, 1);
        this.stakeholderSupplierContacts.splice(index, 1);
        this.updateDropdownOptions();
      },
      reject: (type: any) => {
      
      }
  });
      }
      else
      {
        this.stakeholdersuppliercontactData.splice(index, 1);
        this.stakeholderSupplierContacts.splice(index, 1);
      }
  }

  supplierContactDetails(event: any, index: number): void {
    const selectedSupplierContactId = event.value;

    // Check if the selected supplier is already in the stakeholder list
    const isDuplicate = this.stakeholderSupplierContacts.some(
      (contact) => contact?.id === selectedSupplierContactId
    );

    if (isDuplicate) {
      this.messageService.add({
        key: 'tc',
        severity: 'warn',
        summary: 'Contact already selected.',
        detail: '',
      });
      setTimeout(() => {
        this.stakeholdersuppliercontactData[index].id=0;
        event.value = null; 
      }, 0);
  
      return;
    } else {
      // Add the selected stakeholder contact
      const selectedSupplierContact = this.supplierContactData.find(
        (contact) => contact.id === selectedSupplierContactId
      );
      this.stakeholderSupplierContacts[index] = selectedSupplierContact;

      this.updateDropdownOptions();
    }
  }
updateDropdownOptions(): void {
    const selectedOwnerId = this.contractDetails.OwnerSupplierContactId;
    const selectedStakeholderIds = this.stakeholderSupplierContacts
      .filter(contact => contact && contact.id)
      .map(contact => contact.id);

    this.filteredSupplierContacts = [
      ...this.stakeholderSupplierContacts.filter(contact => contact && contact.id), // Keep selected stakeholders
      ...this.supplierContactData.filter(contact => !selectedStakeholderIds.includes(contact.id) && contact.id !== selectedOwnerId)
    ];

    this.filteredStakholderSupplierContacts = [
      ...this.supplierContactData.filter(contact => !selectedStakeholderIds.includes(contact.id))
    ];
  }

  sponsorContactDetails(event: any, index: number): void {
    const selectedSponsorContactId = event.value;

    const isDuplicate = this.stakeholderSponsorContacts.some(
      (contact) => contact?.id === selectedSponsorContactId
    );

    if (isDuplicate) {
      this.messageService.add({
        key: 'tc',
        severity: 'warn',
        summary: 'Contact already selected.',
        detail: '',
      });
      setTimeout(() => {
        this.stakeholdercontactData[index].id=0;
        event.value = null; 
      }, 0);
  
      return;
    } else {
      const selectedSponsorContact = this.sponsorContactsDataSource.find(
        (contact) => contact.id === selectedSponsorContactId
      );
      this.stakeholderSponsorContacts[index] = selectedSponsorContact;

      this.updateSponsorDropdownOptions();
    }
  }
  updateSponsorDropdownOptions(): void {
    const selectedOwnerId = this.contractDetails.SponserContactId;
    const selectedStakeholderIds = this.stakeholderSponsorContacts
      .filter(contact => contact && contact.id)
      .map(contact => contact.id);

    this.filteredSponsorContacts = [
      ...this.stakeholderSponsorContacts.filter(contact => contact && contact.id), // Keep selected stakeholders
      ...this.sponsorContactsDataSource.filter(contact => !selectedStakeholderIds.includes(contact.id) && contact.id !== selectedOwnerId)
    ];

    this.filteredStakholderSponsorContacts = [
      ...this.sponsorContactsDataSource.filter(contact => !selectedStakeholderIds.includes(contact.id))
    ];
  }
  ExcludeStakeholderContacts(contactType:string,Id:number=0){
    if(contactType=='sponsor')
    {
    this.filteredSponsorContacts = this.totalSponsorContact.filter((contact: { id: number; }) => contact.id !== Id);
     }
     if(contactType=='supplier'){
        this.filteredSupplierContacts = this.totalSupplierContacts.filter((contact: { id: number; }) => contact.id !== Id);
   }
   }
}
